<template>
  <div class="chat-with-gpt-container">
    <img
      v-if="initLoading"
      class="initLoading-gpt"
      :src="require('@/assets/img/general/carga.gif')"
    />
    <template v-else>
      <img
        @click="showConfirmation = true"
        class="gpt-reload-logo"
        :src="Reload"
        alt="ReloadGpt"
      />
      <div
        v-if="showConfirmation"
        @click="cancelConfirmation"
        class="gpt-confirmation-fade"
      />
      <div v-if="showConfirmation" class="gpt-confirmation-container">
        {{ langFilter("confirmationMsg") }}
        <div>
          <button @click="cancelConfirmation">
            {{ langFilter("cancel") }}
          </button>
          <button @click="acceptConfirmation">
            {{ langFilter("accept") }}
          </button>
        </div>
      </div>
      <div class="chat-texts-div" ref="chatContainer">
        <div class="gpt-user-container">
          <div class="gpt-dialoge gpt-user-dialoge no-left row">
            <div class="col-2">
              <img :src="ChatGPTLogo" alt="gptBot" />
            </div>
            <div class="col-10">
              <span
                >{{ langFilter("welcome") }} {{ user_info.first_name }},
                {{ langFilter("howHelp") }}</span
              >
            </div>
          </div>
        </div>
        <div
          v-for="(
            previousUserMessage, previousUserMessagesIndex
          ) in previousUserMessages"
          :key="previousUserMessagesIndex"
          class="gpt-user-container"
        >
          <div @copy="stripHtml" class="gpt-dialoge gpt-chat-dialoge row">
            <div class="col-11">
              <span>{{ previousUserMessage }}</span>
            </div>
            <div class="col-1">
              <img :src="getAvatar()" alt="gptBot" class="gpt-user-logo" />
            </div>
          </div>
          <div class="gpt-user-container">
            <div @copy="stripHtml" class="gpt-dialoge gpt-user-dialoge row">
              <div class="col-1">
                <img
                  class="gpt-logo-chatbot gpt-init-logo"
                  :src="ChatGPTLogo"
                  alt="gptBot"
                />
              </div>
              <div class="col-10">
                <img
                  v-if="
                    previousBotMessages[previousUserMessagesIndex] == undefined
                  "
                  class="loading-gpt"
                  :src="require('@/assets/img/general/carga.gif')"
                />
                <span
                  v-else-if="
                    previousBotMessages[previousUserMessagesIndex] == 'Error'
                  "
                  class="gpt-error-color"
                  >{{ langFilter("gptError") }}
                  <br />
                  <button @click="showConfirmation = true" class="chaterrorbtn">
                    {{ langFilter("newChat") }}
                  </button>
                  <!-- <a href="mailto:support@acceleralia.com"
                    >support@acceleralia.com</a
                  > -->
                </span>
                <span
                  v-else
                  v-html="previousBotMessages[previousUserMessagesIndex]"
                />
              </div>
              <div class="col-1">
                <img
                  @click="copyToClipboard(previousUserMessagesIndex)"
                  class="gpt-copy-logo pointer"
                  :src="Copy"
                  alt="copy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <form class="form-chatbot" @submit="sendMessage($event)">
        <input
          @input="message = $event.target.value"
          class="chat-with-gpt-input"
          :value="message"
          type="text"
        />
        <button class="gpt-send-btn">
          <img class="gpt-send-logo" :src="Send" alt="send" />
        </button>
      </form>
    </template>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
import ChatGPTLogo from "../solution/images/chatgpt_logo.svg.png";
import CloseGPT from "../solution/images/close-gpt.png";
import Send from "../solution/images/send.png";
import Copy from "./images/copy.png";
import Reload from "../solution/images/reload.png";
export default {
  props: ["lang", "space"],
  data() {
    return {
      CloseGPT: CloseGPT,
      ChatGPTLogo: ChatGPTLogo,
      Copy: Copy,
      chatOpened: false,
      Send: Send,
      Reload: Reload,
      message: "",
      user_info: {},
      previousUserMessages: [],
      previousBotMessages: [],
      showConfirmation: false,
      initLoading: true,
      traducciones: [
        {
          name: "gptError",
          es: "Has llegado al límite de consultas, por favor inicie un nuevo chat.",
          en: "You have reached the limit of queries, please start a new chat.",
        },
        {
          name: "gptError2",
          es: "Ha ocurrido un error al conectar con ChatGPT, por favor inténtelo de nuevo de más tarde o contacte con:",
          en: "An error has occurred while connecting to ChatGPT, please try again later or contact with:",
        },
        {
          name: "newChat",
          es: "Nuevo Chat",
          en: "New Chat",
        },
        {
          name: "confirmationMsg",
          es: "¿Estás seguro de que quieres reiniciar la conversación?",
          en: "Are you sure you want to restart the conversation?",
        },
        {
          name: "accept",
          es: "Aceptar",
          en: "Accept",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "welcome",
          es: "Bienvenido",
          en: "Welcome",
        },
        {
          name: "howHelp",
          es: "soy ChatGPT, ¿En que puedo ayudarte?",
          en: "I'm ChatGPT, how can I help you?",
        },
      ],
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/edit-profile/get_user`)
      .then((response) => {
        this.user_info = response.data;
      });
    axios
      .get(`${process.env.VUE_APP_API_URL}/getUserChatGpt`)
      .then((response) => {
        this.initLoading = false;
        response.data.messages.forEach((message) => {
          this.previousUserMessages.push(message.message);
          this.previousBotMessages.push(message.response);
          setTimeout(() => {
            const chatContainer = this.$refs.chatContainer;
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }, 200);
        });
      });
  },
  methods: {
    stripHtml(e) {
      e.preventDefault();
      const text = window.getSelection().toString();
      e.clipboardData.setData("text/plain", text);
    },
    acceptConfirmation() {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/removeUserChatGpt`)
        .then(() => {
          this.previousUserMessages = [];
          this.previousBotMessages = [];
          this.showConfirmation = false;
        });
    },
    cancelConfirmation() {
      this.showConfirmation = false;
    },
    copyToClipboard(i) {
      const textArea = document.createElement("textarea");
      textArea.value = this.previousBotMessages[i];
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    },
    sendMessage(e) {
      e.preventDefault();
      if (this.message !== "") {
        const user_id = this.user_info.id;
        this.previousUserMessages.push(this.message);
        this.message = "";
        setTimeout(() => {
          const chatContainer = this.$refs.chatContainer;
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 200);

        axios
          .post(`${process.env.VUE_APP_API_URL}/chatgpt`, {
            previousUserMessages: this.previousUserMessages,
            previousBotMessages: this.previousBotMessages,
            user_id,
          })
          .then((res) => {
            const data = res.data.generated_response;
            this.previousBotMessages.push(data);
          })
          .catch(() => {
            this.previousBotMessages.push("Error");
          })
          .finally(() => {
            const chatContainer = this.$refs.chatContainer;
            chatContainer.scrollTop = chatContainer.scrollHeight;
          });
      }
    },
    getAvatar() {
      if (this.user_info.avatar.includes("http")) {
        // remove blank spaces
        this.user_info.avatar = this.user_info.avatar.replace(/ /g, "%20");
        return this.user_info.avatar;
      } else {
        return process.env.VUE_APP_API_STORAGE + this.user_info.avatar;
      }
    },
  },
  mixins: [translationMixin],
};
</script>
