<template>
  <div>
    <head>
      <link rel="stylesheet" type="text/css" href="/css/faqs.css" />

      <title>FAQs - Acceleralia</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/img/general/favicon.png" />
    </head>
    <header-general
      :src="require('@/assets/img/feedback/contacto.svg')"
      :title="langFilter('contacto')"
    ></header-general>
    <div class="vistaFaqs">
      <barraContact :lang="lang"></barraContact>

      <div v-if="view == 1">
        <div class="consulta">
          <div class="textoConsulta" v-html="langFilter('text')"></div>
          <div class="textoConsulta">
            {{ langFilter("correo") }}:
            <a href="mailto:support@acceleralia.com">support@acceleralia.com</a>
          </div>
        </div>
        <div class="espacioCategorias">
          <div class="container">
            <div class="row" style="justify-content: center">
              <div
                class="cajaCategoria col-3 m-3"
                :id="'categoria' + category.id"
                v-for="category in categories"
                :key="category.id"
                v-on:click="showAnswers(category.id)"
              >
                <span class="textoCategoria">
                  {{ category.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="view == 2">
        <div class="consulta">
          <div class="textoConsulta" v-html="langFilter('text')"></div>
          <ul
            v-for="answer in filteredAnswers"
            :key="answer.id"
            class="preguntaConsulta"
          >
            <li
              class="tarjetaPregunta"
              :class="{ show: answer.id == tarjetaSelected }"
              :id="'question' + answer.id"
              v-on:click="dropdown(answer.id)"
            >
              {{ answer.question }}
              <div
                :id="'answer' + answer.id"
                class="respuesta"
                :class="{ show: answer.id == tarjetaSelected }"
                v-html="answer.answer"
              ></div>
            </li>
            <div
              :id="'consultaResuelta' + answer.id"
              class="consultaResuelta"
              :class="{ show: answer.id == tarjetaSelected }"
            >
              {{ langFilter("questionSolved") }}?
              <div id="botonesConsulta">
                <div id="resueltaNo" v-on:click="redirect('no')">No</div>
                <div id="resueltaSi" v-on:click="redirect('yes')">
                  {{ langFilter("yes") }}
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div v-if="view == 3">
        <feedback
          :lang="lang"
          image="/img/feedback/estupendo.svg"
          feedbackView="3"
        ></feedback>
      </div>
      <div v-if="view == 4">
        <div class="consulta">
          <div class="textoConsulta">
            {{ langFilter("descripcion") }}
          </div>
          <div class="inputs">
            <label class="consultaHeader"> {{ langFilter("nombre") }}</label>
            <input
              id="nombreApellido"
              v-model="userName"
              v-on:keyup="checkText"
              :placeholder="langFilter('nombre-placeholder')"
              readonly
            />
            <label class="consultaHeader">Email</label>
            <input
              id="correo"
              v-model="userEmail"
              v-on:keyup="checkText"
              :placeholder="langFilter('email-placeholder')"
              readonly
            />
            <label class="consultaHeader"> {{ langFilter("consulta") }}</label>
            <textarea
              id="areaConsulta"
              :placeholder="langFilter('consulta-placeholder')"
              v-on:keyup="checkText"
              v-model="userQuestion"
            ></textarea>
          </div>
          <button
            id="enviar"
            ref="enviar"
            class="enviarNoClickable"
            v-on:click="submit()"
          >
            {{ langFilter("enviar") }}
          </button>
        </div>
      </div>

      <div v-if="view == 5">
        <feedback
          :lang="lang"
          image="/img/feedback/antenas.svg"
          feedbackView="5"
        ></feedback>
      </div>

      <div v-if="view == 0">
        <img class="carga2" :src="require('@/assets/img/general/carga.gif')" />
      </div>
    </div>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import barraContact from "./BarraContactComponent.vue";
import headerGeneral from "../general/HeaderComponent.vue";
import feedback from "../FeedbackComponent.vue";
import axios from "axios";

export default {
  components: {
    barraContact,
    headerGeneral,
    feedback,
  },
  props: ["lang", "ruta"],
  data() {
    return {
      categories: {},
      answers: {},
      answers2: ``,
      filteredAnswers: {},
      userName: "",
      userEmail: "",
      userQuestion: "",
      view: 0,
      tarjetaSelected: 0,
      traducciones: [
        {
          name: "text",
          es: "Danos los detalles y te ofreceremos la mejor solución.<br />Explícanos cuál es tu incidencia y uno de nuestros expertos se pondrá en contacto contigo.<br /><br />Selecciona el tema que mejor se ajusta a tu incidencia.",
          en: "Give us the details and we will offer you the best solution.<br />Tell us what your incident is and one of our experts will contact you.<br /><br />Select the topic that best fits your issue.",
        },
        {
          name: "correo",
          es: "Si lo prefieres también puedes ponerte en contacto con nosotros a través de nuestro correo electrónico",
          en: "If you prefer you can also contact us through our email",
        },
        {
          name: "text",
          es: "Ayúdanos a entender más, para ayudarte mejor.<br /><br />Aquí podrás encontrar las consultas más frecuentes referente a los Recursos.",
          en: "Help us understand more, to help you better.<br /><br />Here you can find the most frequent queries regarding Resources.",
        },
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
        {
          name: "consulta",
          es: "Consulta",
          en: "Query",
        },
        {
          name: "descripcion",
          es: "Si quieres puedes ponerte en contacto con uno de nuestros expertos",
          en: "If you want, you can contact one of our experts.",
        },
        {
          name: "nombre-placeholder",
          es: "Escribe aquí tu nombre...",
          en: "Write here your name...",
        },
        {
          name: "email-placeholder",
          es: "Escribe aquí tu email...",
          en: "Write here your email...",
        },
        {
          name: "consulta-placeholder",
          es: "Escribe aquí tu consulta...",
          en: "Write here your query...",
        },
        {
          name: "enviar",
          es: "Enviar",
          en: "Send",
        },
        {
          name: "nombre",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "questionSolved",
          es: "¿Hemos resuelto tu consulta",
          en: "Has the question been solved",
        },
        {
          name: "yes",
          es: "Sí",
          en: "Yes",
        },
        {
          name: "no",
          es: "No",
          en: "No",
        },
      ],
    };
  },
  methods: {
    popStateReload() {
      // al iniciar la pantalla, dependiendo del path, se carga una vista u otra
      if (window.location.pathname == "/faqs") {
        this.view = 1;
      } else if (window.location.pathname.includes("/faqs/")) {
        this.view = 2;
      } else if (window.location.pathname == "/feedback/support-great/") {
        this.view = 3;
      } else if (window.location.pathname == "/customer-support/") {
        this.userQuestion = "";
        this.view = 4;
      } else if (
        window.location.pathname == "/feedback/support-message-sent/"
      ) {
        this.view = 5;
      }

      if (this.view == 1) {
        this.view = 0;
        this.view = 1;
      } else if (this.view == 2) {
        this.view = 0;

        var url = window.location.href;
        var id = url.substring(url.lastIndexOf("/") + 1);

        setTimeout(() => {
          this.filteredAnswers = this.answers.filter((answer) => {
            return answer.category_id == id;
          });
          this.view = 2;
        }, 2000);
      } else if (this.view == 3) {
        this.view = 0;
        this.view = 3;
      } else if (this.view == 4) {
        this.view = 0;
        this.userQuestion = "";
        this.view = 4;
      } else if (this.view == 5) {
        this.view = 0;
        this.view = 5;
      }

      setTimeout(() => {
        this.$parent.loading = false;
      }, 1000);
    },

    // carga todas las categorías
    getCategories() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/listCategories`, {
          lang: this.lang,
        })
        .then((response) => {
          this.categories = response.data.categories;
        });
    },

    // carga todas las respuestas
    getAnswers() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/listAnswers`, {
          lang: this.lang,
        })
        .then((response) => {
          this.answers = response.data.answers;
        });
    },

    // carga el nmo y el email del usuario activo
    getNameEmail() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/getNameEmail`)
        .then((response) => {
          this.userName = response.data.name;
          this.userEmail = response.data.email;
        });
    },

    // al hacer click en una pregunta, esta se expande, revelando su respuesta
    dropdown(id) {
      if (this.tarjetaSelected == 0) {
        this.tarjetaSelected = id;
      } else if (this.tarjetaSelected == id) {
        this.tarjetaSelected = 0;
      } else {
        this.tarjetaSelected = id;
      }
    },

    // al hacer click al sí/no de la pregunta, se redirige a una página u otra
    redirect(answer) {
      if (answer == "yes") {
        //   window.location.href=window.location.origin+'/feedback/support-great/';
        history.pushState(null, null, "/feedback/support-great/");
        history.replaceState(null, null, "/feedback/support-great/");
        this.view = 0;
        setTimeout(() => {
          this.view = 3;
        }, 500);
      } else if (answer == "no") {
        //   window.location.href=window.location.origin+'/feedback/customer-support/';
        history.pushState(null, null, "/customer-support/");
        history.replaceState(null, null, "/customer-support/");
        this.userQuestion = "";
        this.view = 0;
        setTimeout(() => {
          this.view = 4;
        }, 500);
      }
    },

    // al hacer click en una categoría, se muestran las preguntas de esa categoría
    showAnswers(id) {
      // window.location.href=window.location.origin+'/faqs/'+id;
      // this.view=2;
      history.pushState(null, null, "/faqs/" + id);
      history.replaceState(null, null, "/faqs/" + id);
      this.filteredAnswers = this.answers.filter((answer) => {
        return answer.category_id == id;
      });

      this.view = 0;
      setTimeout(() => {
        this.view = 2;
      }, 500);
    },

    // si todos los campos del mail de soporte están rellenos, se activa el botón de enviar
    checkText() {
      if (
        (this.userName != "") &
        (this.userEmail != "") &
        (this.userQuestion != "")
      ) {
        this.$refs.enviar.classList.remove("enviarNoClickable");
        this.$refs.enviar.classList.add("enviarClickable");
      } else {
        this.$refs.enviar.classList.remove("enviarClickable");
        this.$refs.enviar.classList.add("enviarNoClickable");
      }
    },

    // envía el mail de soporte, añade el mensaje a la base de datos y redirige a la página de mensaje enviado
    submit() {
      if (this.$refs.enviar.classList.contains("enviarClickable")) {
        axios.post(
          `${process.env.VUE_APP_API_URL}/customer-support-mail/add-email`,
          {
            message: this.userQuestion,
            email: this.userEmail,
            name: this.userName,
          }
        );
        axios.post(
          `${process.env.VUE_APP_API_URL}/customer-support-mail/send-email`,
          {
            bodyText: this.userQuestion,
            email: this.userEmail,
            nombre: this.userName,
          }
        );
        history.pushState(null, null, "/feedback/support-message-sent/");
        history.replaceState(null, null, "/feedback/support-message-sent/");
        this.view = 0;
        setTimeout(() => {
          this.view = 5;
        }, 500);
      }
    },
  },

  //utiliza popStateReload para cargar la página debida según la url, y carga las categorías y las respuestas
  created() {
    this.view = this.ruta;
    setTimeout(
      function () {
        this.popStateReload();
      }.bind(this),
      1
    );

    this.getCategories();
    this.getAnswers();
    this.getNameEmail();
  },
  mixins: [translationMixin],
};
</script>
