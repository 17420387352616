<template>
  <div>
    <div class="sidebar-corporation-name-container active-up">
      <h4
        v-if="space != undefined"
        @click="showAdminPanelfunction()"
        id="sidebar-ap-arrow"
        :class="[{ pointer: role == 1 }, 'sidebar-corporation-name']"
      >
        {{ space.name }}
        <img
          v-if="role == 1"
          class="sidebar-arrow-down"
          id="sidebar-arrow-down"
          :src="require('@/assets/img/sidebar/arrow_down.svg')"
          alt="admin_panel"
        />
      </h4>
      <div
        v-if="role == 1 && showAdminPanel"
        v-outside="closeAdminPanel"
        @click="handleClick()"
        class="sidebar-adminpanel-opc"
      >
        <router-link to="/adminpanel">
          <img :src="require('@/assets/img/sidebar/gears.svg')" alt="gears" />
          <span class="sidebar-adminpanel-span">Admin Panel</span>
        </router-link>
      </div>
    </div>

    <sidebar-option
      @click="handleClick()"
      v-for="(option, optionIndex) in $parent.options"
      :key="optionIndex"
      :option="option"
      :optionIndex="optionIndex"
      :lang="lang"
      :optionSelected="getOptionSelected(optionIndex)"
      :class="[
        {
          'sidebar-background-selected': getOptionSelected(optionIndex),
        },
        'sidebar-container-options',
      ]"
    />

    <div class="sidebar-footer" v-show="!$parent.closed">
      <div class="sidebar-background-container">
        <div
          class="sidebar-container-options"
          @click="handleClick()"
          @mouseover="sidebarHover(50)"
          @mouseleave="leaveSidebarHover(50)"
          :class="{
            'sidebar-background-selected': 50 == page,
          }"
        >
          <div v-show="50 == page" class="sidebar-option-content" >
            
            <router-link to="/faqs">
              <img
                :src="require('@/assets/img/sidebar/hovers/icon-help.svg')"
                class="sidebar-option-image"
              />
              <p class="sidebar-option-text sidebar-option-selected">
                {{ langFilter("help") }}
              </p>
            </router-link>
          </div>
          <div v-show="50 !== page" class="sidebar-option-content">
            
            <router-link to="/faqs">
              <img
                :src="require('@/assets/img/sidebar/logos/icon-help.svg')"
                class="sidebar-option-image"
              />
              <p class="sidebar-option-text">
                {{ langFilter("help") }}
              </p>
            </router-link>
            <div
              id="sidebar-mouse-over-5"
              style="right: -5px"
              class="sidebar-mouse-over"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
export default {
  data() {
    return {
      showAdminPanel: false,

      traducciones: [
        {
          name: "help",
          es: "¿Ayuda?",
          en: "Need help?",
        },
      ],
    };
  },
  props: ["space", "role", "page", "lang", "old"],
  methods: {
    handleClick() {
      this.showAdminPanel = false;
    },
    closeAdminPanel(el) {
      if (
        el.target.id !== "sidebar-ap-arrow" &&
        el.target.id !== "sidebar-arrow-down"
      ) {
        this.showAdminPanel = false;
      }
      if (el.target.id == "sidebar-arrow-down") {
        this.showAdminPanel = true;
      }
    },
    showAdminPanelfunction() {
      if (this.role == 1) {
        this.showAdminPanel = !this.showAdminPanel;
      }
    },
    sidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "#4bedb1");
    },
    leaveSidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "");
    },
    getOptionSelected(optionIndex) {
      if (
        optionIndex == 2 &&
        (this.page == 102 || this.page == 101 || this.page == 105)
      ) {
        return true;
      }
      return optionIndex == this.page;
    },
  },
  mixins: [translationMixin],
};
</script>
