<template>
    <div class="new-template-title-container">
        <div class="new-template-title">
            {{ stepNum + 1 }}.
            <span v-if="stepNum === 0">
                {{ langFilter("title0") }}
            </span>
            <span v-else-if="stepNum === 1">
                {{ langFilter("title1") }}
            </span>
            <span v-else-if="stepNum === 2">
                {{ langFilter("title2") }}
            </span>
        </div>
        <div class="new-template-subtitle">
            <p v-if="stepNum === 0">
                {{ langFilter("subtitle0") }}
            </p>
            <p v-else-if="stepNum === 1">
                {{ langFilter("subtitle1") }}
            </p>
            <p v-else>
                {{ langFilter("subtitle2") }}
            </p>
        </div>
    </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
export default {
    props: ["lang", "stepNum"],
    data() {
        return {
            traducciones: [
                {
                    name: "subtitle0",
                    es: "Inserta el nombre de la plantilla.",
                    en: "Insert the template name.",
                },
                {
                    name: "subtitle1",
                    es: "Para cambiar la imagen de portada, selecciona desde tus archivos la imagen que deseas.",
                    en: "To change the cover image, select the image you want from your files.",
                },
                {
                    name: "subtitle2",
                    es: "La categoría sirve para identificar más rápidamente la temática de la plantilla. Puedes seleccionar hasta tres categorías",
                    en: "The category is used to identify the template's theme quickly. You can select up to three categories",
                },
                {
                    name: "title0",
                    es: "Título plantilla*",
                    en: "Template name*",
                },
                {
                    name: "title1",
                    es: "Imagen de portada",
                    en: "Cover image",
                },
                {
                    name: "title2",
                    es: "Categoría de la plantilla",
                    en: "Template category",
                },
            ],
        };
    },
    created() {},
    mounted() {},
    methods: {},
    mixins: [translationMixin],
};
</script>
