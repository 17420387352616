<template>
  <div class="sol-center-header">
    <div
      :class="[
        { 'solution-closed-carrousel': !showHeader },
        'solutionCarrousel container-solutionCarrousel allign-center-projects',
      ]"
    >
      <carrousel
	ref="carrousel"
        @changeProject="changeProject($event)"
        :project_id="project_id"
        :loading="loading"
        :lang="lang"
        :space="space"
        page="solution"
        :showHeader="showHeader"
        :percentage="percentage"
        :role="role"
      />
      <div
        @click="showHeader = !showHeader"
        class="solution-close-header div-center"
      >
        <img
          v-if="showHeader"
          :src="require('@/assets/img/solution/arrow-up.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
        <img
          v-else
          :src="require('@/assets/img/solution/arrow-down.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Carrousel from "../general/CarrouselComponent.vue";
import axios from "axios";

export default {
  components: {
    Carrousel,
  },
  props: [
    "mapOpened",
    "project_id",
    "solution_id",
    "station_id",
    "loading",
    "lang",
    "percentage",
    "space",
    "role",
  ],
  data() {
    return {
      projectCarrousel: [],
      selectOpened: false,
      initCarrousel: 0,
      finalCarrousel: 3,
      showHeader: true,
      percentagesSecondaries: [0, 0, 0],
    };
  },
  methods: {
	loadCarrousel(){
		this.$refs.carrousel.on_created_carrousel();
	},
    changeProject(project) {
      this.$parent.loading = true;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/getFirstStationSolutionByProject`,
          {
            params: {
              project_id: project,
            },
          }
        )
        .then((response) => {
          this.$emit("changeProject", [
            project,
            response.data[0],
            response.data[1],
          ]);
        });
    },
  },
};
</script>
