<template>
  <div>
    <div class="login-sidebar">
      <img
        class="login-sidebar-logo"
        :src="require('@/assets/img/sidebar/acc_logo.png')"
      />
      <p class="login-sidebar-text">
        {{ langFilter("login with your") }}
        <span>{{ langFilter("account") }}</span>
      </p>
    </div>
    <div class="login-main-container">
      <h1 class="login-header">
        {{ langFilter("Nice to see you again.") }}
      </h1>
      <h1 class="login-second-header">
        <span>{{ langFilter("Log in") }}</span>
        {{ langFilter("to access.") }}
      </h1>
      <input
        type="text"
        class="login-input"
        id="input-user"
        :placeholder="langFilter('Email or Username')"
        style="margin-top: 70px"
        v-model="user_login.user"
        v-on:keyup.enter="submitLogin"
      />
      <div class="login-input-container">
        <div class="login-input-div">
          <input
            :type="passwordVisibility ? 'text' : 'password'"
            class="login-input-pwd"
            id="input-password"
            :placeholder="langFilter('Password')"
            v-model="user_login.password"
            v-on:keyup.enter="submitLogin"
          />
          <img
            v-if="!passwordVisibility"
            :src="require('@/assets/img/general/Eye_Default_Closed.png')"
            class="input-password-eye"
            @click="passwordVisible"
            @mouseenter="hoverEye"
            @mouseleave="hoverEyeLeave"
          />
          <img
            v-else
            :src="require('@/assets/img/general/Eye_Default_Open.png')"
            class="input-password-eye"
            @click="passwordVisible"
            @mouseenter="hoverEyeOpen"
            @mouseleave="hoverEyeOpenLeave"
          />
        </div>
      </div>
      <div class="login-checkbox" style="height: 28px">
        <div class="register-checkbox-container">
          <input
            @change="remind_me = !remind_me"
            id="remember"
            type="checkbox"
          />
          <label for="remember">{{ langFilter("remember") }}</label>
          <label class="pointer" @click="goToForgotPassword">{{
            langFilter("Forgot password")
          }}</label>
        </div>
      </div>
      <div class="login-button" style="margin-top: 30px">
        <p class="login-button-text" @click="submitLogin">
          {{ langFilter("Log In") }}
        </p>
      </div>
      <div class="login-5min" v-if="maxAttempts">
        <img src="/img/auth/Moon-07-1.png" class="login-alert-rocket" />
        <p class="login-alert-title">Usuario bloqueado temporalmente</p>
        <p class="login-alert-mins">5 min</p>
        <p class="login-alert-secondtitle">Vuelve a intentarlo más tarde.</p>
      </div>
    </div>

    <alertMessage :lang="lang" ref="alerts"></alertMessage>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
import router from "@/router";

export default {
  props: ["url", "wrong"],

  data() {
    return {
      lang: "en",
      remind_me: false,
      user_login: {
        user: "",
        password: "",
      },
      passwordVisibility: false,
      maxAttempts: false,
      errors: [],
      succesful: [],
      //Traducción
      traducciones: [
        {
          name: "login with your",
          es: "Inicia sesión con tu",
          en: "Login with your",
        },
        {
          name: "account",
          es: "cuenta",
          en: "account",
        },
        {
          name: "Nice to see you again.",
          es: "Qué bien volver a verte.",
          en: "Nice to see you again.",
        },
        {
          name: "Log in",
          es: "Inicia sesión",
          en: "Log in",
        },
        {
          name: "Log In",
          es: "Iniciar sesión",
          en: "Log In",
        },
        {
          name: "to access.",
          es: "para acceder.",
          en: "to access.",
        },
        {
          name: "Remind me",
          es: "Recordarme",
          en: "Remind me",
        },
        {
          name: "Forgot password",
          es: "Recuperar contraseña",
          en: "Forgot password",
        },
        {
          name: "Password",
          es: "Contraseña",
          en: "Password",
        },
        {
          name: "Email or Username",
          es: "Email o Nombre de usuario",
          en: "Email or Username",
        },
        {
          name: "remember",
          es: "Recuerdame",
          en: "Remember me",
        },
      ],
    };
  },
  created() {
    this.lang = navigator.language.substring(0, 2);
  },
  mounted() {
    if (this.wrong == "error") {
      $("#input-user").addClass("input-error");
      $("#input-password").addClass("input-error");
      this.$refs.alerts.title = "Incorrect data entered!";
      this.$refs.alerts.text = "the email and/or password do not match.";
      this.openErrorsAlert();
    }
  },
  methods: {
    passwordVisible() {
      this.passwordVisibility = !this.passwordVisibility;
    },
    submitLogin() {
      $("#input-user").removeClass("input-error");
      $("#input-password").removeClass("input-error");

      if (this.checkFields()) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/submitlogin`, {
            email: this.user_login.user,
            password: this.user_login.password,
            remember: this.remind_me,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");
            window.location.replace("/home");
          })
          .catch(() => {
            $("#input-user").addClass("input-error");
            $("#input-password").addClass("input-error");
            this.$refs.alerts.title = "Incorrect data entered!";
            this.$refs.alerts.text = "the email and/or password do not match.";
            this.openErrorsAlert();
          });
      }
    },

    checkFields() {
      if (this.user_login.password == "" && this.user_login.user == "") {
        $("#input-user").addClass("input-error");
        $("#input-password").addClass("input-error");
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
        return false;
      } else if (this.user_login.password == "") {
        $("#input-password").addClass("input-error");
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
        return false;
      } else if (this.user_login.user == "") {
        $("#input-user").addClass("input-error");
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
        return false;
      }
      return true;
    },

    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    goToForgotPassword() {
      router.push({ path: "/forgot-password" });
    },
    hoverEye(el) {
      $(el.target).attr("src", "/img/register/Eye_Hover_Closed.png");
    },
    hoverEyeLeave(el) {
      $(el.target).attr("src", "/img/register/Eye_Default_Closed.png");
    },
    hoverEyeOpen(el) {
      $(el.target).attr("src", "/img/register/Eye_Hover_Open.png");
    },
    hoverEyeOpenLeave(el) {
      $(el.target).attr("src", "/img/register/Eye_Default_Open.png");
    },
  },
  mixins: [translationMixin],
};
</script>
