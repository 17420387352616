<template>
  <div class="members-title">
    <span>{{ langFilter("projMembers") }}</span>
  </div>
  <itinerary-users-modal
    @closeModal="addingUsers = -1"
    @addUsers="getUsers"
    @addProjectUsers="addProjectUsers($event)"
    v-if="addingUsers !== -1"
    :lang="lang"
    :space="space"
    :project_id="project_id"
    :addingUsers="addingUsers"
  />
  <template v-for="(userData, userIndex) in users" :key="userIndex">
    <div class="container itinerary-users-title">
      {{ langFilter(userIndex === 0 ? "tutors" : "members") }}
      <div class="row">
        <div class="col-12">
          <button
            v-if="editing"
            @click="addingUsers = userIndex"
            class="itinerary-add-btn itinerary-add-users-btn"
          >
            +
          </button>
        </div>
      </div>
    </div>
    <itinerary-users-table
      v-for="(user, i) in userData"
      @removeUser="removeUser($event)"
      :key="i"
      :i="i"
      :lang="lang"
      :editing="editing"
      :type="userIndex"
      :user="user"
    />
  </template>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "editing", "space", "project_id"],
  data() {
    return {
      addingUsers: -1,
      users: [],
      traducciones: [
        {
          name: "projMembers",
          es: "MIEMBROS DEL PROYECTO",
          en: "PROJECT MEMBERS",
        },
        {
          name: "members",
          es: "Miembros",
          en: "Members",
        },
        {
          name: "tutors",
          es: "Tutor/es",
          en: "Tutor/s",
        },
      ],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getUsersBySpaceInProject/${this.space.id}/${this.project_id}`
        )
        .then((res) => {
          this.users = [res.data.tutors, res.data.members];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeUser({ type, index }) {
      const user = this.users[type][index];
      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/removeUserFromProject/${user.id}/${this.project_id}`
        )
        .then(() => {
          this.users[type].splice(index, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addProjectUsers(users) {
      users.tutors.forEach((user) => {
        if (this.users[0].find((u) => u.id === user.id))
          this.users[0] = this.users[0].filter((u) => u.id !== user.id);
        if (this.users[1].find((u) => u.id === user.id))
          this.users[1] = this.users[1].filter((u) => u.id !== user.id);
        this.users[0].push(user);
      });
      users.members.forEach((user) => {
        if (this.users[0].find((u) => u.id === user.id))
          this.users[0] = this.users[0].filter((u) => u.id !== user.id);
        if (this.users[1].find((u) => u.id === user.id))
          this.users[1] = this.users[1].filter((u) => u.id !== user.id);
        this.users[1].push(user);
      });
      if (this.$route.path.includes("new-project") && users.invited) {
        users.invited.forEach((email) => {
          const user = {
            email: email,
            id: 0,
            name: email,
            role: users.role,
            avatar:
              "https://aceleralia.com/wp-content/uploads/2021/05/Avatar-8-150x150.jpg",
          };
          this.users[users.role].push(user);
        });
      }
      this.$emit("usersInvite", {
        tutors: this.users[0],
        members: this.users[1],
        invited: users.invited,
      });
    },
  },
  mixins: [translationMixin],
};
</script>
