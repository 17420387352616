<template>
  <div class="right-sidebar">
    <button
      @click="showView(i)"
      v-for="(button, i) in buttons"
      :key="i"
      :class="view === i ? 'sidebar-btn-bg-selected' : 'sidebar-btn-bg'"
    >
      <hover :content="button">
        <img
          :src="require(`@/assets/img/right-sidebar/${button}.svg`)"
          :alt="button"
        />
      </hover>
    </button>
  </div>
</template>

<script>
export default {
  props: ["buttons", "view"],
  data() {
    return {};
  },
  methods: {
    showView(i) {
      this.$emit("showView", i);
    },
  },
};
</script>
