<template>
  <div class="new-template-body">
    <div v-if="stepNum === 0" class="newtemplate-container-div">
      <div>
        <input
          @input="setTitle($event)"
          :value="myTitle"
          :placeholder="langFilter('writeTitle')"
          class="new-template-input-name"
          maxlength="58"
          minlength="1"
          type="text"
        />

        <p class="count-title">{{ myTitle.length }}/58</p>
      </div>
    </div>
    <div v-if="stepNum === 1">
      <div class="newtemplate-container-div">
        <button
          @click="$refs.file.click()"
          :style="`background-image: url('${templateImage}')`"
          :class="[
            { 'new-template-setedImg': templateImage !== '' },
            'newItinerary-changeImageBtn',
          ]"
        >
          <div v-if="templateImage !== ''" class="newItinerary-selectImg">
            <img
              :src="require('@/assets/img/track/camera.svg')"
              alt="camera logo"
            />
            <br />
            <span class="newTemplate-select-img-txt">{{
              langFilter("selectImg")
            }}</span>
          </div>
          <div v-else>{{ langFilter("changeImage") }}</div>
        </button>
      </div>
      <p class="newtemplate-max">[Max: 2MB]</p>
      <input
        @change="uploadFile"
        type="file"
        ref="file"
        accept="image/png, image/gif, image/jpeg"
        class="hide"
      />
    </div>
    <div v-show="stepNum === 2" class="newtemplate-container-div">
      <div
        @click="selectCategory(category)"
        v-for="(category, categoryIndex) in categories"
        :id="'category-' + category.id"
        :key="categoryIndex"
        :class="[
          {
            'newTemplate-category-div-not-selected': !categoryChecked(category),
          },
          {
            'newTemplate-category-div-selected': categoryChecked(category),
          },
          'newTemplate-category-div row pointer',
        ]"
      >
        <div class="newTemplate-indicator col-1">{{ categoryIndex + 1 }}.</div>
        <div class="col-9 elipsis">{{ category.name }}</div>
        <div
          :class="[
            {
              'newTemplate-checkBox-selected': categoryChecked(category),
            },
            'newTemplate-checkBox col-2',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["lang", "stepNum", "title", "draftCategories"],
  data() {
    return {
      myTitle: "",
      templateImage: "",
      imageName: "",
      categories: [],
      categoriesSelected: [],
      traducciones: [
        {
          name: "writeTitle",
          es: "Escribe el título aquí...",
          en: "Write the title here...",
        },
        {
          name: "changeImage",
          es: "Cambiar imagen",
          en: "Change image",
        },
        {
          name: "selectImg",
          es: "Selecciona una imagen",
          en: "Select an image",
        },
      ],
    };
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/new-template/getCategories/${this.lang}`
      )
      .then((response) => {
        this.categories = response.data;
      });
  },
  methods: {
    setTitle(event) {
      const data = event.target.value;
      if (data.length <= 40) {
        this.myTitle = data;
      }
      this.$emit("setTitle", data);
      if (data.length > 0) {
        this.$emit("enableNext", true);
      } else {
        this.$emit("enableNext", false);
      }
    },
    uploadFile(el) {
      const file = el.target.files[0];
      if (file.size > 2000000) {
        this.$emit("errorFileUpload");
        alert(
          "El archivo es demasiado grande, por favor selecciona uno con un peso menor a 2MB"
        );
      } else {
        const formData = new FormData();
        formData.append("file", file);
        if (this.templateImage !== "") {
          formData.append("oldImage", this.templateImage);
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/new-template/uploadTemplateImage`,
            formData
          )
          .then((response) => {
            this.templateImage =
              process.env.VUE_APP_API_STORAGE + response.data;
            this.$emit("setTemplateImage", response.data);
          });
      }
    },
    selectCategory(category) {
      //check if category is already selected
      if (this.categoriesSelected.includes(category)) {
        //remove category from array
        this.categoriesSelected = this.categoriesSelected.filter(
          (item) => item !== category
        );
      } else {
        this.categoriesSelected.push(category);
      }
      this.$emit("setCategories", this.categoriesSelected);
    },
    categoryChecked(category) {
      return this.categoriesSelected.includes(category);
    },
  },
  mounted() {
    setTimeout(() => {
      this.draftCategories.forEach((category) => {
        document.getElementById("category-" + category.id).click();
      });
    }, 2000);
  },
  watch: {
    title: function (newTitle) {
      this.myTitle = newTitle;
    },
  },
  mixins: [translationMixin],
};
</script>
