<style>
@import "../../src/assets/css/admin_panel/admin_panel.css";
</style>

<script setup>
import AdminPanel from "../components/admin_panel/AdminPanelComponent.vue";
</script>

<template>
  <AdminPanel :space="spaces[0]" :lang="lang" />
</template>

<script>
export default {
  props: ["spaces", "role", "lang"],
};
</script>
