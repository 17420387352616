<template>
  <div>
    <div class="vistaCustomerSupport">
      <barraContact :lang="lang"></barraContact>
      <div class="consulta">
        <div class="textoConsulta">
          {{ langFilter("descripcion") }}
        </div>
        <div class="inputs">
          <label class="consultaHeader"> {{ langFilter("nombre") }}</label>
          <input
            id="nombreApellido"
            ref="nombreApellido"
            v-on:keyup="checkText"
            :placeholder="langFilter('nombre-placeholder')"
            :value="name"
            readonly
          />
          <label class="consultaHeader">Email</label>
          <input
            id="correo"
            ref="correo"
            v-on:keyup="checkText"
            :placeholder="langFilter('email-placeholder')"
            :value="email"
            readonly
          />
          <label class="consultaHeader"> {{ langFilter("consulta") }}</label>
          <textarea
            id="areaConsulta"
            :placeholder="langFilter('consulta-placeholder')"
            v-on:keyup="checkText"
            ref="areaConsulta"
          ></textarea>
        </div>
        <button
          id="enviar"
          ref="enviar"
          class="enviarNoClickable"
          v-on:click="
            addEmail();
            submit();
          "
        >
          {{ langFilter("enviar") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
import barraContact from "./faqs/BarraContactComponent.vue";
import axios from "axios";
import $ from "jquery";

export default {
  components: {
    barraContact,
  },
  props: ["lang", "email", "name"],
  data() {
    return {
      traducciones: [
        {
          name: "title_support_screen",
          es: "Customer support",
          en: "Customer support",
        },
        {
          name: "consulta",
          es: "Consulta",
          en: "Query",
        },
        {
          name: "descripcion",
          es: "Si quieres puedes ponerte en contacto con uno de nuestros expertos",
          en: "If you want, you can contact one of our experts.",
        },
        {
          name: "nombre-placeholder",
          es: "Escribe aquí tu nombre...",
          en: "Write here your name...",
        },
        {
          name: "email-placeholder",
          es: "Escribe aquí tu email...",
          en: "Write here your email...",
        },
        {
          name: "consulta-placeholder",
          es: "Escribe aquí tu consulta...",
          en: "Write here your query...",
        },
        {
          name: "enviar",
          es: "Enviar",
          en: "Send",
        },
        {
          name: "nombre",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "contacto",
          es: "Contacto",
          en: "Contact",
        },
      ],
    };
  },
  created() {
    $(".myProjects-SecondHeaderContainer").hide();
    $(".carga").hide();
    $(".myProjects-MainContainer").show();
  },
  methods: {
    btnclick() {
      if (this.header == "true") {
        window.history.go(-1);
      } else {
        window.location.href = this.langFilter(this.link);
      }
    },
    checkText() {
      if (
        (this.$refs.areaConsulta.value != "") &
        (this.$refs.correo.value != "") &
        (this.$refs.nombreApellido.value != "")
      ) {
        this.$refs.enviar.classList.remove("enviarNoClickable");
        this.$refs.enviar.classList.add("enviarClickable");
      } else {
        this.$refs.enviar.classList.remove("enviarClickable");
        this.$refs.enviar.classList.add("enviarNoClickable");
      }
    },
    submit() {
      if (this.$refs.enviar.classList.contains("enviarClickable")) {
        axios
          .post("/customer-support-mail/send-email", {
            bodyText: this.$refs.areaConsulta.value,
            email: this.$refs.correo.value,
            nombre: this.$refs.nombreApellido.value,
          })
          .then(() => {})
          .catch(function () {});
        window.location.href = "../../feedback/support-message-sent/";
      }
    },
    addEmail() {
      if (this.$refs.enviar.classList.contains("enviarClickable")) {
        axios
          .post("/customer-support-mail/add-email", {
            message: this.$refs.areaConsulta.value,
            email: this.$refs.correo.value,
            name: this.$refs.nombreApellido.value,
          })
          .then(() => {})
          .catch(function () {});
      }
    },
  },

  mixins: [translationMixin],
};
</script>
