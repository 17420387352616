<template>
  <terms-sidebar :policy="true" />
  <div class="terms-container">
    <div
      align="center"
      style=" border-box;background-image: initial !important;background-position: initial !important;background-size: initial !important;background-repeat: initial !important;background-attachment: initial !important;background-origin: initial !important;background-clip: initial !important;background-color: rgb(255, 255, 255);color: rgb(33, 37, 41);font-family: Poppins-Regular, sans-serif;font-size: 14.4px;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-weight: 400;letter-spacing: normal;orphans: 2;text-indent: 0px;text-transform: none;white-space: normal;widows: 2;word-spacing: 0px;-webkit-text-stroke-width: 0px;text-decoration-thickness: initial;text-decoration-style: initial;text-decoration-color: initial;text-align: left;line-height: 1.5;"
    >
      <div
        align="center"
        style=" border-box;background-image: initial !important;background-position: initial !important;background-size: initial !important;background-repeat: initial !important;background-attachment: initial !important;background-origin: initial !important;background-clip: initial !important;background-color: rgb(255, 255, 255);color: rgb(33, 37, 41);font-family: Poppins-Regular, sans-serif;font-size: 14.4px;font-style: normal;font-variant-ligatures: normal;font-variant-caps: normal;font-weight: 400;letter-spacing: normal;orphans: 2;text-indent: 0px;text-transform: none;white-space: normal;widows: 2;word-spacing: 0px;-webkit-text-stroke-width: 0px;text-decoration-thickness: initial;text-decoration-style: initial;text-decoration-color: initial;text-align: left;line-height: 1.5;"
      >
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <strong
            style="
              box-sizing: border-box;
              font-weight: bolder;
              background: transparent !important;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 26px;
              "
              ><span
                data-custom-class="title"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-family: Poppins !important;
                  font-size: 26px !important;
                  color: rgb(0, 0, 0) !important;
                "
                >PRIVACY NOTICE</span
              ></span
            ></strong
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(127, 127, 127);
            "
            ><strong
              style="
                box-sizing: border-box;
                font-weight: bolder;
                background: transparent !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                "
                ><span
                  data-custom-class="subtitle"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-family: Poppins !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                  "
                  >Last updated&nbsp;March 23, 2021</span
                ></span
              ></strong
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(127, 127, 127);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 15px;
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >Thank you for choosing to be part of our community
                at&nbsp;ACCELERALIA S.L. (&quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Company</strong
                ></span
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >&quot;, &quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >we</strong
                >&quot;, &quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >us</strong
                >&quot;, &quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >our</strong
                >&quot;). We are committed to protecting your personal
                information and your right to privacy. If you have any questions
                or concerns about this privacy notice, or our practices with
                regards to your personal information, please contact us
                at&nbsp;legal@acceleralia.com.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><br
              style="
                box-sizing: border-box;
                background: transparent !important;
              "
          /></span>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >When you&nbsp;visit our website&nbsp;<a
                  data-custom-class="link"
                  href="http://app.acceleralia.com/"
                  style="
                    box-sizing: border-box;
                    color: rgb(48, 48, 241) !important;
                    text-decoration: none !important;
                    background: transparent !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                    word-break: break-word !important;
                  "
                  target="_blank"
                  >http://app.acceleralia.com</a
                >
                (the &quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Website</strong
                >&quot;),&nbsp;and more generally, use any of our services (the
                &quot;<strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Services</strong
                >&quot;, which include the&nbsp;</span
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >Website</span
                    ></span
                  ></span
                ></span
              ></span
            ><span
              data-custom-class="body_text"
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89) !important;
                font-size: 14px !important;
                font-family: Poppins !important;
              "
              >), we appreciate that you are trusting us with your personal
              information. We take your privacy very seriously. In this privacy
              notice, we seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you
              have in relation to it. We hope you take some time to read through
              it carefully, as it is important. If there are any terms in this
              privacy notice that you do not agree with, please discontinue use
              of our Services immediately.</span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><br
              style="
                box-sizing: border-box;
                background: transparent !important;
              "
          /></span>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >This privacy notice applies to all information collected
                  through our Services (which, as described above, includes
                  our&nbsp;</span
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  ></span
                ></span
              ></span
            ></span
          ><span
            data-custom-class="body_text"
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(89, 89, 89) !important;
              font-size: 14px !important;
              font-family: Poppins !important;
            "
            >), as well as, any related services, sales, marketing or
            events.</span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><br
              style="
                box-sizing: border-box;
                background: transparent !important;
              "
          /></span>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Please read this privacy notice carefully as it will help
                    you understand what we do with the information that we
                    collect.</strong
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><br
              style="
                box-sizing: border-box;
                background: transparent !important;
              "
          /></span>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(0, 0, 0);
                "
                ><strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                  "
                  ><span
                    data-custom-class="heading_1"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-family: Poppins !important;
                      font-size: 19px !important;
                      color: rgb(0, 0, 0) !important;
                    "
                    >TABLE OF CONTENTS</span
                  ></strong
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><br
              style="
                box-sizing: border-box;
                background: transparent !important;
              "
          /></span>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#infocollect"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >1. WHAT INFORMATION DO WE COLLECT?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#infouse"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >2. HOW DO WE USE YOUR INFORMATION?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
              "
              ><a
                data-custom-class="link"
                href="https://app.acceleralia.com/privacy-policy#infoshare"
                style="
                  box-sizing: border-box;
                  color: rgb(48, 48, 241) !important;
                  text-decoration: none !important;
                  background: transparent !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#cookies"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#sociallogins"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                    font-size: 14px !important;
                    font-family: Poppins !important;
                    word-break: break-word !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 14px !important;
                      font-family: Poppins !important;
                      word-break: break-word !important;
                    "
                    >5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span
                  ></span
                ></span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#inforetain"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >6. HOW LONG DO WE KEEP YOUR INFORMATION?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#infosafe"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#infominors"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >8. DO WE COLLECT INFORMATION FROM MINORS?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
              "
              ><a
                data-custom-class="link"
                href="https://app.acceleralia.com/privacy-policy#privacyrights"
                style="
                  box-sizing: border-box;
                  color: rgb(48, 48, 241) !important;
                  text-decoration: none !important;
                  background: transparent !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >9. WHAT ARE YOUR PRIVACY RIGHTS?</a
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#DNT"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >10. CONTROLS FOR DO-NOT-TRACK FEATURES</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#caresidents"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
            "
            ><a
              data-custom-class="link"
              href="https://app.acceleralia.com/privacy-policy#policyupdates"
              style="
                box-sizing: border-box;
                color: rgb(48, 48, 241) !important;
                text-decoration: none !important;
                background: transparent !important;
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 14px !important;
                  font-family: Poppins !important;
                  word-break: break-word !important;
                "
                >12. DO WE MAKE UPDATES TO THIS NOTICE?</span
              ></a
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <a
            data-custom-class="link"
            href="https://app.acceleralia.com/privacy-policy#contact"
            style="
              box-sizing: border-box;
              color: rgb(48, 48, 241) !important;
              text-decoration: none !important;
              background: transparent !important;
              font-size: 14px !important;
              font-family: Poppins !important;
              word-break: break-word !important;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 15px;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              >13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
            ></a
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <a
            data-custom-class="link"
            href="https://app.acceleralia.com/privacy-policy#request"
            style="
              box-sizing: border-box;
              color: rgb(48, 48, 241) !important;
              text-decoration: none !important;
              background: transparent !important;
              font-size: 14px !important;
              font-family: Poppins !important;
              word-break: break-word !important;
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 14px !important;
                font-family: Poppins !important;
                word-break: break-word !important;
              "
              >14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
              YOU?</span
            ></a
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(127, 127, 127);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-size: 15px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(0, 0, 0);
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                      "
                      ><span
                        data-custom-class="heading_1"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 19px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        >1. WHAT INFORMATION DO WE COLLECT?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            data-custom-class="heading_2"
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-family: Poppins !important;
              font-size: 17px !important;
              color: rgb(0, 0, 0);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-family: Poppins !important;
                font-size: 15px;
                color: rgb(0, 0, 0) !important;
              "
              ><strong
                style="
                  box-sizing: border-box;
                  font-weight: bolder;
                  background: transparent !important;
                  font-family: Poppins !important;
                  font-size: 17px !important;
                  color: rgb(0, 0, 0) !important;
                "
                ><u
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-family: Poppins !important;
                    font-size: 17px !important;
                    color: rgb(0, 0, 0) !important;
                  "
                  ><br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-family: Poppins !important;
                      font-size: 17px !important;
                      color: rgb(0, 0, 0) !important;
                    " /></u></strong
              ><strong
                style="
                  box-sizing: border-box;
                  font-weight: bolder;
                  background: transparent !important;
                  font-family: Poppins !important;
                  font-size: 17px !important;
                  color: rgb(0, 0, 0) !important;
                "
                >Personal information you disclose to us</strong
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <div
            style="box-sizing: border-box; background: transparent !important"
          >
            <br
              style="box-sizing: border-box; background: transparent !important"
            />
          </div>
          <div
            style="
              box-sizing: border-box;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 15px;
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ></span
                      ></span
                    ></span
                  ></span
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >We collect personal information that you provide to
                          us.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >We collect personal information that you voluntarily provide to
                us when you&nbsp;register on the&nbsp;</span
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Website,&nbsp;</span
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >express an interest in obtaining information about us or our
                  products and Services, when you participate in activities on
                  the&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  >
                  (such as by posting messages in our online forums or entering
                  competitions, contests or giveaways)</span
                ></span
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >&nbsp;or otherwise when you contact us.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >The personal information that we collect depends on the context
                of your interactions with us and the&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  >, the choices you make and the products and features you use.
                  The personal information we collect may include the
                  following:</span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                ><strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Personal Information Provided by You.</strong
                >
                We collect&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >names;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >phone numbers;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >email addresses;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >mailing addresses;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >usernames;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >passwords;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >contact or authentication data;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >billing addresses;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >debit/credit card numbers;&nbsp;</span
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >and other similar information.</span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                ><strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Payment Data.</strong
                >
                We may collect data necessary to process your payment if you
                make purchases, such as your payment instrument number (such as
                a credit card number), and the security code associated with
                your payment instrument. All payment data is stored by
                Stripe<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >. You may find their privacy notice link(s) here:
                      <a
                        data-custom-class="link"
                        href="https://stripe.com/es/privacy"
                        style="
                          box-sizing: border-box;
                          color: rgb(48, 48, 241) !important;
                          text-decoration: none !important;
                          background: transparent !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                          word-break: break-word !important;
                        "
                        target="_blank"
                        >https://stripe.com/es/privacy</a
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 15px;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >.</span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                ><strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >Social Media Login Data.&nbsp;</strong
                >We may provide you with the option to register with us using
                your existing social media account details, like your Facebook,
                Twitter or other social media account. If you choose to register
                in this way, we will collect the information described in the
                section called &quot;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><a
                          data-custom-class="link"
                          href="https://app.acceleralia.com/privacy-policy#sociallogins"
                          style="
                            box-sizing: border-box;
                            color: rgb(48, 48, 241) !important;
                            text-decoration: none !important;
                            background: transparent !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                            word-break: break-word !important;
                          "
                          >HOW DO WE HANDLE YOUR SOCIAL LOGINS</a
                        ></span
                      ></span
                    ></span
                  ></span
                >&quot; below.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            data-custom-class="heading_2"
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-family: Poppins !important;
              font-size: 17px !important;
              color: rgb(0, 0, 0);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-family: Poppins !important;
                font-size: 15px;
                color: rgb(0, 0, 0) !important;
              "
              ><strong
                style="
                  box-sizing: border-box;
                  font-weight: bolder;
                  background: transparent !important;
                  font-family: Poppins !important;
                  font-size: 17px !important;
                  color: rgb(0, 0, 0) !important;
                "
                ><u
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-family: Poppins !important;
                    font-size: 17px !important;
                    color: rgb(0, 0, 0) !important;
                  "
                  ><br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-family: Poppins !important;
                      font-size: 17px !important;
                      color: rgb(0, 0, 0) !important;
                    " /></u></strong
              ><strong
                style="
                  box-sizing: border-box;
                  font-weight: bolder;
                  background: transparent !important;
                  font-family: Poppins !important;
                  font-size: 17px !important;
                  color: rgb(0, 0, 0) !important;
                "
                >Information automatically collected</strong
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <div
            style="box-sizing: border-box; background: transparent !important"
          >
            <br
              style="box-sizing: border-box; background: transparent !important"
            />
          </div>
          <div
            style="
              box-sizing: border-box;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 15px;
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ></span
                      ></span
                    ></span
                  ></span
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Some information &mdash; such as your Internet
                          Protocol (IP) address and/or browser and device
                          characteristics &mdash; is collected automatically
                          when you visit our&nbsp;<span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><em
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 15px;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></em
                            ></span
                          >.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >We automatically collect certain information when you visit,
                use or navigate the&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  ></span
                >. This information does not reveal your specific identity (like
                your name or contact information) but may include device and
                usage information, such as your IP address, browser and device
                characteristics, operating system, language preferences,
                referring URLs, device name, country, location, information
                about how and when you use our&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  ></span
                >
                and other technical information. This information is primarily
                needed to maintain the security and operation of our&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  ></span
                >, and for our internal analytics and reporting purposes.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >Like many businesses, we also collect information through
                cookies and similar technologies.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >The information we collect includes:</span
              ></span
            ></span
          >
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><em
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Log and Usage Data.</em
                  >
                  Log and usage data is service-related, diagnostic, usage and
                  performance information our servers automatically collect when
                  you access or use our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Website</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                  and which we record in log files. Depending on how you
                  interact with us, this log data may include your IP address,
                  device information, browser type and settings and information
                  about your activity in the&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Website</span
                          ></span
                        ></span
                      ></span
                    >&nbsp;</span
                  >(such as the date/time stamps associated with your usage,
                  pages and files viewed, searches and other actions you take
                  such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  &apos;crash dumps&apos;) and hardware settings).</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><em
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Device Data.</em
                  >
                  We collect device data such as information about your
                  computer, phone, tablet or other device you use to access
                  the&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >Website</span
                    ></span
                  >. Depending on the device used, this device data may include
                  information such as your IP address (or proxy server), device
                  and application identification numbers, location, browser
                  type, hardware model Internet service provider and/or mobile
                  carrier, operating system and system configuration
                  information.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><em
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Location Data.</em
                  >
                  We collect location data such as information about your
                  device&apos;s location, which can be either precise or
                  imprecise. How much information we collect depends on the type
                  and settings of the device you use to access the&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Website</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >. For example, we may use GPS and other technologies to
                  collect geolocation data that tells us your current location
                  (based on your IP address). You can opt out of allowing us to
                  collect this information either by refusing access to the
                  information or by disabling your Location setting on your
                  device. Note however, if you choose to opt out, you may not be
                  able to use certain aspects of the Services.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(127, 127, 127);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-size: 15px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(0, 0, 0);
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                      "
                      ><span
                        data-custom-class="heading_1"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 19px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        >2. HOW DO WE USE YOUR INFORMATION?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <div
            style="
              box-sizing: border-box;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <br
              style="box-sizing: border-box; background: transparent !important"
            />
          </div>
          <div
            style="
              box-sizing: border-box;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(127, 127, 127);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                  font-size: 15px;
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >We process your information for purposes based on
                          legitimate business interests, the fulfillment of our
                          contract with you, compliance with our legal
                          obligations, and/or your consent.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >We use personal information collected via our&nbsp;<span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 15px;
                    font-family: Poppins !important;
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Website</span
                  ></span
                >
                for a variety of business purposes described below. We process
                your personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.</span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >We use the information we collect or receive:</span
              ></span
            ></span
          >
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To facilitate account creation and logon process.</strong
                  >
                  If you choose to link your account with us to a third-party
                  account (such as your Google or Facebook account), we use the
                  information you allowed us to collect from those third parties
                  to facilitate account creation and logon process for the
                  performance of the contract.<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      >&nbsp;</span
                    ></span
                  >See the section below headed &quot;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><a
                        data-custom-class="link"
                        href="https://app.acceleralia.com/privacy-policy#sociallogins"
                        style="
                          box-sizing: border-box;
                          color: rgb(48, 48, 241) !important;
                          text-decoration: none !important;
                          background: transparent !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                          word-break: break-word !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(48, 48, 241) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                            word-break: break-word !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                              word-break: break-word !important;
                            "
                            >HOW DO WE HANDLE YOUR SOCIAL LOGINS</span
                          ></span
                        ></a
                      ></span
                    ></span
                  >&quot; for further information.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To post testimonials.</strong
                  >
                  We post testimonials on our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Website</span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                  that may contain personal information. Prior to posting a
                  testimonial, we will obtain your consent to use your name and
                  the content of the testimonial. If you wish to update, or
                  delete your testimonial, please contact us at&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >legal@acceleralia.com</span
                      ></span
                    ></span
                  >
                  and be sure to include your name, testimonial location, and
                  contact information.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Request feedback.&nbsp;</strong
                  >We may use your information to request feedback and to
                  contact you about your use of our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Website</span
                            ></span
                          ></span
                        >.</span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To enable user-to-user communications.</strong
                  >
                  We may use your information in order to enable user-to-user
                  communications with each user&apos;s consent.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To manage user accounts.&nbsp;</strong
                  >We may use your information for the purposes of managing our
                  account and keeping it in working order.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To send administrative information to you.&nbsp;</strong
                  >We may use your personal information to send you product,
                  service and new feature information and/or information about
                  changes to our terms, conditions, and policies.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To protect our Services.&nbsp;</strong
                  >We may use your information as part of our efforts to keep
                  our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Website</span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                  safe and secure (for example, for fraud monitoring and
                  prevention).</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To enforce our terms, conditions and policies for business
                    purposes, to comply with legal and regulatory requirements
                    or in connection with our contract.</strong
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >To respond to legal requests and prevent
                      harm.&nbsp;</strong
                    >If we receive a subpoena or other legal request, we may
                    need to inspect the data we hold to determine how to
                    respond.</span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <p
          style="
            box-sizing: border-box;
            margin-bottom: 1rem;
            margin-top: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            font-size: 15px;
          "
        >
          <br />
        </p>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >Fulfill and manage your orders.&nbsp;</strong
                    >We may use your information to fulfill and manage your
                    orders, payments, returns, and exchanges made through
                    the&nbsp;<span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 15px;
                        font-family: Poppins !important;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 15px;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Website.</span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <p
          style="
            box-sizing: border-box;
            margin-bottom: 1rem;
            margin-top: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            font-size: 15px;
          "
        >
          <br />
        </p>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >Administer prize draws and competitions.</strong
                    >
                    We may use your information to administer prize draws and
                    competitions when you elect to participate in our
                    competitions.</span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <p
          style="
            box-sizing: border-box;
            margin-bottom: 1rem;
            margin-top: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            font-size: 15px;
          "
        >
          <br />
        </p>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >To deliver and facilitate delivery of services to the
                      user.</strong
                    >
                    We may use your information to provide you with the
                    requested service.</span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <p
          style="
            box-sizing: border-box;
            margin-bottom: 1rem;
            margin-top: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            font-size: 15px;
          "
        >
          <br />
        </p>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    data-custom-class="body_text"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >To respond to user inquiries/offer support to
                      users.</strong
                    >
                    We may use your information to respond to your inquiries and
                    solve any potential issues you might have with the use of
                    our Services.</span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >To send you marketing and promotional
                    communications.</strong
                  >
                  We and/or our third-party marketing partners may use the
                  personal information you send to us for our marketing
                  purposes, if this is in accordance with your marketing
                  preferences. For example, when expressing an interest in
                  obtaining information about us or our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 15px;
                      font-family: Poppins !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Website</span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >, subscribing to marketing or otherwise contacting us, we
                  will collect personal information from you. You can opt-out of
                  our marketing emails at any time (see the &quot;<a
                    data-custom-class="link"
                    href="https://app.acceleralia.com/privacy-policy#privacyrights"
                    style="
                      box-sizing: border-box;
                      color: rgb(48, 48, 241) !important;
                      text-decoration: none !important;
                      background: transparent !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                      word-break: break-word !important;
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(48, 48, 241) !important;
                        font-size: 15px;
                        font-family: Poppins !important;
                        word-break: break-word !important;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 14px !important;
                          font-family: Poppins !important;
                          word-break: break-word !important;
                        "
                        >WHAT ARE YOUR PRIVACY RIGHTS</span
                      ></span
                    ></a
                  >&quot; below).</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Deliver targeted advertising to you.</strong
                  ></span
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >&nbsp;We may use your information to develop and display
                  personalized content and advertising (and work with third
                  parties who do so) tailored to your interests and/or location
                  and to measure its effectiveness.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >For other business purposes.</strong
                  ></span
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >&nbsp;We may use your information for other business
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our&nbsp;<span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89);
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    ><span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Website</span
                        ></span
                      ></span
                    ></span
                  >, products, marketing and your experience. We may use and
                  store this information in aggregated and anonymized form so
                  that it is not associated with individual end users and does
                  not include personal information. We will not use identifiable
                  personal information without your consent.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              color: rgb(127, 127, 127);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                color: rgb(89, 89, 89);
                font-size: 15px;
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    font-size: 15px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(0, 0, 0);
                    "
                    ><strong
                      style="
                        box-sizing: border-box;
                        font-weight: bolder;
                        background: transparent !important;
                      "
                      ><span
                        data-custom-class="heading_1"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 19px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        >3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                ><strong
                  style="
                    box-sizing: border-box;
                    font-weight: bolder;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><em
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >In Short:</em
                  ></strong
                ><em
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  >&nbsp;We only share information with your consent, to comply
                  with laws, to provide you with services, to protect your
                  rights, or to fulfill business obligations.</em
                ></span
              ></span
            ></span
          >
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <br
            style="box-sizing: border-box; background: transparent !important"
          />
        </div>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >We may process or share your data that we hold based on the
                following legal basis:</span
              ></span
            ></span
          >
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Consent:</strong
                  >
                  We may process your data if you have given us specific consent
                  to use your personal information for a specific purpose.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Legitimate Interests:</strong
                  >
                  We may process your data when it is reasonably necessary to
                  achieve our legitimate business interests.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Performance of a Contract:</strong
                  >
                  Where we have entered into a contract with you, we may process
                  your personal information to fulfill the terms of our
                  contract.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Legal Obligations:</strong
                  >
                  We may disclose your information where we are legally required
                  to do so in order to comply with applicable law, governmental
                  requests, a judicial proceeding, court order, or legal
                  process, such as in response to a court order or a subpoena
                  (including in response to public authorities to meet national
                  security or law enforcement requirements).</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <br />
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Vital Interests:</strong
                  >
                  We may disclose your information where we believe it is
                  necessary to investigate, prevent, or take action regarding
                  potential violations of our policies, suspected fraud,
                  situations involving potential threats to the safety of any
                  person and illegal activities, or as evidence in litigation in
                  which we are involved.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            line-height: 1.5;
          "
        >
          <span
            style="
              box-sizing: border-box;
              background: transparent !important;
              font-size: 15px;
              color: rgb(89, 89, 89);
            "
            ><span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                data-custom-class="body_text"
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  color: rgb(89, 89, 89) !important;
                  font-size: 14px !important;
                  font-family: Poppins !important;
                "
                >More specifically, we may need to process your data or share
                your personal information in the following situations:</span
              ></span
            ></span
          >
        </div>
        <ul
          style="
            box-sizing: border-box;
            list-style: none;
            padding: 0px;
            margin: 0px;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <li
            style="
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              list-style: none;
              background: transparent !important;
              line-height: 1.5;
            "
          >
            <span
              style="
                box-sizing: border-box;
                background: transparent !important;
                font-size: 15px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                  font-size: 15px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  data-custom-class="body_text"
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    color: rgb(89, 89, 89) !important;
                    font-size: 14px !important;
                    font-family: Poppins !important;
                  "
                  ><strong
                    style="
                      box-sizing: border-box;
                      font-weight: bolder;
                      background: transparent !important;
                      color: rgb(89, 89, 89) !important;
                      font-size: 14px !important;
                      font-family: Poppins !important;
                    "
                    >Business Transfers.</strong
                  >
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div
          style="
            box-sizing: border-box;
            background-image: initial !important;
            background-position: initial !important;
            background-size: initial !important;
            background-repeat: initial !important;
            background-attachment: initial !important;
            background-origin: initial !important;
            background-clip: initial !important;
            background-color: rgb(255, 255, 255);
            color: rgb(33, 37, 41);
            font-family: Poppins-Regular, sans-serif;
            font-size: 14.4px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
          "
        >
          <div
            style="box-sizing: border-box; background: transparent !important"
          >
            <div
              style="box-sizing: border-box; background: transparent !important"
            >
              <div
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                "
              >
                <br />
              </div>
              <ul
                style="
                  box-sizing: border-box;
                  list-style: none;
                  padding: 0px;
                  margin: 0px;
                  background: transparent !important;
                "
              >
                <li
                  style="
                    box-sizing: border-box;
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Business Partners.&nbsp;</strong
                        >We may share your information with our business
                        partners to offer you certain products, services or
                        promotions.</span
                      ></span
                    ></span
                  >
                </li>
              </ul>
              <div
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                "
              >
                <br />
              </div>
              <ul
                style="
                  box-sizing: border-box;
                  list-style: none;
                  padding: 0px;
                  margin: 0px;
                  background: transparent !important;
                "
              >
                <li
                  style="
                    box-sizing: border-box;
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Other Users.&nbsp;</strong
                        >When you share personal information&nbsp;(for example,
                        by posting comments, contributions or other content to
                        the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >)&nbsp;or otherwise interact with public areas of
                        the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >, such personal information may be viewed by all users
                        and may be publicly made available outside
                        the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        in perpetuity.&nbsp;If you interact with other users of
                        our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        and register for our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        through a social network (such as Facebook), your
                        contacts on the social network will see your name,
                        profile photo, and descriptions of your
                        activity.&nbsp;Similarly, other users will be able to
                        view descriptions of your activity, communicate with you
                        within our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >Website</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >, and view your profile.</span
                      ></span
                    ></span
                  >
                </li>
              </ul>
              <div
                style="
                  box-sizing: border-box;
                  background: transparent !important;
                "
              >
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <br />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >4. DO WE USE COOKIES AND OTHER TRACKING
                                TECHNOLOGIES?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >We may use cookies and other tracking technologies to
                          collect and store your information.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to access or store
                        information. Specific information about how we use such
                        technologies and how you can refuse certain cookies is
                        set out in our Cookie Notice<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >If you choose to register or log in to our services
                          using a social media account, we may have access to
                          certain information about you.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >Our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89) !important;
                                        font-size: 15px;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89);
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        ><span
                                          data-custom-class="body_text"
                                          style="
                                            box-sizing: border-box;
                                            background: transparent !important;
                                            color: rgb(89, 89, 89) !important;
                                            font-size: 14px !important;
                                            font-family: Poppins !important;
                                          "
                                          >Website</span
                                        ></span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        offers you the ability to register and login using your
                        third-party social media account details (like your
                        Facebook or Twitter logins). Where you choose to do
                        this, we will receive certain profile information about
                        you from your social media provider. The profile
                        information we receive may vary depending on the social
                        media provider concerned, but will often include your
                        name, email address, friends list, profile picture as
                        well as other information you choose to make public on
                        such social media platform.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We will use the information we receive only for the
                        purposes that are described in this privacy notice or
                        that are otherwise made clear to you on the
                        relevant&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        >Website</span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >. Please note that we do not control, and are not
                        responsible for, other uses of your personal information
                        by your third-party social media provider. We recommend
                        that you review their privacy notice to understand how
                        they collect, use and share your personal information,
                        and how you can set your privacy preferences on their
                        sites and apps.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >6. HOW LONG DO WE KEEP YOUR INFORMATION?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >We keep your information for as long as necessary to
                          fulfill the purposes outlined in this privacy notice
                          unless otherwise required by law.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting or
                        other legal requirements). No purpose in this notice
                        will require us keeping your personal information for
                        longer than&nbsp;the period of time in which users have
                        an account with us.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is
                        possible.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >We aim to protect your personal information through a
                          system of organizational and technical security
                          measures.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We have implemented appropriate technical and
                        organizational security measures designed to protect the
                        security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security, and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        >Website</span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        is at your own risk. You should only access
                        the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        >Website</span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        within a secure environment.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >8. DO WE COLLECT INFORMATION FROM MINORS?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >&nbsp;We do not knowingly collect data from or market
                          to children under 18 years of age.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        >Website</span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >, you represent that you are at least 18 or that you
                        are the parent or guardian of such a minor and consent
                        to such minor dependent&rsquo;s use of the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        >Website</span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >. If we learn that personal information from users less
                        than 18 years of age has been collected, we will
                        deactivate the account and take reasonable measures to
                        promptly delete such data from our records. If you
                        become aware of any data we may have collected from
                        children under age 18, please contact us at&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >legal@acceleralia.com</span
                          ></span
                        >.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >9. WHAT ARE YOUR PRIVACY RIGHTS?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >&nbsp;In some regions, such as the European Economic
                          Area, you have rights that allow you greater access to
                          and control over your personal information.&nbsp;You
                          may review, change, or terminate your account at any
                          time.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >In some regions (like the European Economic Area), you
                        have certain rights under applicable data protection
                        laws. These may include the right (i) to request access
                        and obtain a copy of your personal information, (ii) to
                        request rectification or erasure; (iii) to restrict the
                        processing of your personal information; and (iv) if
                        applicable, to data portability. In certain
                        circumstances, you may also have the right to object to
                        the processing of your personal information. To make
                        such a request, please use the&nbsp;<a
                          data-custom-class="link"
                          href="https://app.acceleralia.com/privacy-policy#contact"
                          style="
                            box-sizing: border-box;
                            color: rgb(48, 48, 241) !important;
                            text-decoration: none !important;
                            background: transparent !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                            word-break: break-word !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(48, 48, 241) !important;
                              font-size: 15px;
                              font-family: Poppins !important;
                              word-break: break-word !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(48, 48, 241);
                                font-size: 14px !important;
                                font-family: Poppins !important;
                                word-break: break-word !important;
                              "
                              >contact details</span
                            ></span
                          ></a
                        >
                        provided below. We will consider and act upon any
                        request in accordance with applicable data protection
                        laws.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If we are relying on your consent to process your
                        personal information, you have the right to withdraw
                        your consent at any time. Please note however that this
                        will not affect the lawfulness of the processing before
                        its withdrawal, nor will it affect the processing of
                        your personal information conducted in reliance on
                        lawful processing grounds other than consent.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If you are a resident in the European Economic Area and
                        you believe we are unlawfully processing your personal
                        information, you also have the right to complain to your
                        local data protection supervisory authority. You can
                        find their contact details here:&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(48, 48, 241);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><a
                                    data-custom-class="link"
                                    href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                    rel="noopener noreferrer"
                                    style="
                                      box-sizing: border-box;
                                      color: rgb(48, 48, 241) !important;
                                      text-decoration: none !important;
                                      background: transparent !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                      word-break: break-word !important;
                                    "
                                    target="_blank"
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(48, 48, 241) !important;
                                        font-size: 15px;
                                        font-family: Poppins !important;
                                        word-break: break-word !important;
                                      "
                                      >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span
                                    ></a
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If you are a resident in Switzerland, the contact
                        details for the data protection authorities are
                        available here:&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(48, 48, 241);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><a
                                      data-custom-class="link"
                                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                      rel="noopener noreferrer"
                                      style="
                                        box-sizing: border-box;
                                        color: rgb(48, 48, 241) !important;
                                        text-decoration: none !important;
                                        background: transparent !important;
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                        word-break: break-word !important;
                                      "
                                      target="_blank"
                                      >https://www.edoeb.admin.ch/edoeb/en/home.html</a
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If you have questions or comments about your privacy
                        rights, you may email us at&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >legal@acceleralia.com</span
                            ></span
                          ></span
                        >.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    data-custom-class="heading_2"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-family: Poppins !important;
                      font-size: 17px !important;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-family: Poppins !important;
                        font-size: 15px;
                        color: rgb(0, 0, 0) !important;
                      "
                      ><strong
                        style="
                          box-sizing: border-box;
                          font-weight: bolder;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 17px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        ><u
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-family: Poppins !important;
                            font-size: 17px !important;
                            color: rgb(0, 0, 0) !important;
                          "
                          ><br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-family: Poppins !important;
                              font-size: 17px !important;
                              color: rgb(0, 0, 0) !important;
                            " /></u></strong
                      ><strong
                        style="
                          box-sizing: border-box;
                          font-weight: bolder;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 17px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        >Account Information</strong
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  >
                    <br
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    />
                  </div>
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(127, 127, 127);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 15px;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 15px;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >If you would at any time like to review or
                                change the information in your account or
                                terminate your account, you can:</span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </div>
                  <ul
                    style="
                      box-sizing: border-box;
                      list-style: none;
                      padding: 0px;
                      margin: 0px;
                      background: transparent !important;
                    "
                  >
                    <li
                      style="
                        box-sizing: border-box;
                        padding: 0px;
                        margin: 0px;
                        list-style: none;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(127, 127, 127);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  >Log in to your account settings and update
                                  your user account.</span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </li>
                  </ul>
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  >
                    <br />
                  </div>
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Upon your request to terminate your account, we will
                          deactivate or delete your account and information from
                          our active databases. However, we may retain some
                          information in our files to prevent fraud,
                          troubleshoot problems, assist with any investigations,
                          enforce our Terms of Use and/or comply with applicable
                          legal requirements.</span
                        ></span
                      ></span
                    >
                  </div>
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><u
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Cookies and similar technologies:</u
                          ></strong
                        >
                        Most Web browsers are set to accept cookies by default.
                        If you prefer, you can usually choose to set your
                        browser to remove cookies and to reject cookies. If you
                        choose to remove cookies or reject cookies, this could
                        affect certain features or services of our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        ><span
                                          style="
                                            box-sizing: border-box;
                                            background: transparent !important;
                                            color: rgb(89, 89, 89) !important;
                                            font-size: 15px;
                                            font-family: Poppins !important;
                                          "
                                          ><span
                                            style="
                                              box-sizing: border-box;
                                              background: transparent !important;
                                              color: rgb(89, 89, 89);
                                              font-size: 14px !important;
                                              font-family: Poppins !important;
                                            "
                                            ><span
                                              data-custom-class="body_text"
                                              style="
                                                box-sizing: border-box;
                                                background: transparent !important;
                                                color: rgb(
                                                  89,
                                                  89,
                                                  89
                                                ) !important;
                                                font-size: 14px !important;
                                                font-family: Poppins !important;
                                              "
                                              >Website</span
                                            ></span
                                          ></span
                                        ></span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >. To opt-out of interest-based advertising by
                        advertisers on our&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 15px;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89);
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      ><span
                                        data-custom-class="body_text"
                                        style="
                                          box-sizing: border-box;
                                          background: transparent !important;
                                          color: rgb(89, 89, 89) !important;
                                          font-size: 14px !important;
                                          font-family: Poppins !important;
                                        "
                                        ><span
                                          style="
                                            box-sizing: border-box;
                                            background: transparent !important;
                                            color: rgb(89, 89, 89) !important;
                                            font-size: 15px;
                                            font-family: Poppins !important;
                                          "
                                          ><span
                                            style="
                                              box-sizing: border-box;
                                              background: transparent !important;
                                              color: rgb(89, 89, 89);
                                              font-size: 14px !important;
                                              font-family: Poppins !important;
                                            "
                                            ><span
                                              data-custom-class="body_text"
                                              style="
                                                box-sizing: border-box;
                                                background: transparent !important;
                                                color: rgb(
                                                  89,
                                                  89,
                                                  89
                                                ) !important;
                                                font-size: 14px !important;
                                                font-family: Poppins !important;
                                              "
                                              >Website</span
                                            ></span
                                          ></span
                                        ></span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                        visit&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(48, 48, 241);
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><a
                              data-custom-class="link"
                              href="http://www.aboutads.info/choices/"
                              rel="noopener noreferrer"
                              style="
                                box-sizing: border-box;
                                color: rgb(48, 48, 241) !important;
                                text-decoration: none !important;
                                background: transparent !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                                word-break: break-word !important;
                              "
                              target="_blank"
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(48, 48, 241) !important;
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                  word-break: break-word !important;
                                "
                                >http://www.aboutads.info/choices/</span
                              ></a
                            ></span
                          ></span
                        >.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><u
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Opting out of email marketing:</u
                          ></strong
                        >
                        You can unsubscribe from our marketing email list at any
                        time by clicking on the unsubscribe link in the emails
                        that we send or by contacting us using the details
                        provided below. You will then be removed from the
                        marketing email list &mdash; however, we may still
                        communicate with you, for example to send you
                        service-related emails that are necessary for the
                        administration and use of your account, to respond to
                        service requests, or for other non-marketing purposes.
                        To otherwise opt-out, you may:</span
                      ></span
                    ></span
                  >
                </div>
                <ul
                  style="
                    box-sizing: border-box;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background: transparent !important;
                  "
                >
                  <li
                    style="
                      box-sizing: border-box;
                      padding: 0px;
                      margin: 0px;
                      list-style: none;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      data-custom-class="body_text"
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89) !important;
                        font-size: 14px !important;
                        font-family: Poppins !important;
                      "
                      >Access your account settings and update your
                      preferences.</span
                    >
                  </li>
                </ul>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <br />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >10. CONTROLS FOR DO-NOT-TRACK FEATURES</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track
                        (&quot;DNT&quot;) feature or setting you can activate to
                        signal your privacy preference not to have data about
                        your online browsing activities monitored and collected.
                        At this stage no uniform technology standard for
                        recognizing and implementing DNT signals has been
                        finalized. As such, we do not currently respond to DNT
                        browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked
                        online. If a standard for online tracking is adopted
                        that we must follow in the future, we will inform you
                        about that practice in a revised version of this privacy
                        notice.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      color: rgb(127, 127, 127);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(89, 89, 89);
                        font-size: 15px;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(0, 0, 0);
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                              "
                              ><span
                                data-custom-class="heading_1"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  font-family: Poppins !important;
                                  font-size: 19px !important;
                                  color: rgb(0, 0, 0) !important;
                                "
                                >11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                                PRIVACY RIGHTS?</span
                              ></strong
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><em
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In Short:&nbsp;</em
                          ></strong
                        ><em
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Yes, if you are a resident of California, you are
                          granted specific rights regarding access to your
                          personal information.</em
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >California Civil Code Section 1798.83, also known as
                        the &quot;Shine The Light&quot; law, permits our users
                        who are California residents to request and obtain from
                        us, once a year and free of charge, information about
                        categories of personal information (if any) we disclosed
                        to third parties for direct marketing purposes and the
                        names and addresses of all third parties with which we
                        shared personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If you are under 18 years of age, reside in California,
                        and have a registered account with&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 15px;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89);
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      data-custom-class="body_text"
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89) !important;
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      >the Website</span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >, you have the right to request removal of unwanted
                        data that you publicly post on the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 15px;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89);
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      data-custom-class="body_text"
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89) !important;
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      >Website</span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >. To request removal of such data, please contact us
                        using the contact information provided below, and
                        include the email address associated with your account
                        and a statement that you reside in California. We will
                        make sure the data is not publicly displayed on
                        the&nbsp;<span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                            font-family: Poppins !important;
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 15px;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89);
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    ><span
                                      data-custom-class="body_text"
                                      style="
                                        box-sizing: border-box;
                                        background: transparent !important;
                                        color: rgb(89, 89, 89) !important;
                                        font-size: 14px !important;
                                        font-family: Poppins !important;
                                      "
                                      >Website</span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        >, but please be aware that the data may not be
                        completely or comprehensively removed from all our
                        systems (e.g. backups, etc.).</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    data-custom-class="heading_2"
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-family: Poppins !important;
                      font-size: 17px !important;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-family: Poppins !important;
                        font-size: 15px;
                        color: rgb(0, 0, 0) !important;
                      "
                      ><strong
                        style="
                          box-sizing: border-box;
                          font-weight: bolder;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 17px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        ><u
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-family: Poppins !important;
                            font-size: 17px !important;
                            color: rgb(0, 0, 0) !important;
                          "
                          ><br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-family: Poppins !important;
                              font-size: 17px !important;
                              color: rgb(0, 0, 0) !important;
                            " /></u></strong
                      ><strong
                        style="
                          box-sizing: border-box;
                          font-weight: bolder;
                          background: transparent !important;
                          font-family: Poppins !important;
                          font-size: 17px !important;
                          color: rgb(0, 0, 0) !important;
                        "
                        >CCPA Privacy Notice</strong
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  >
                    <br
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    />
                  </div>
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        color: rgb(127, 127, 127);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89);
                          font-size: 15px;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 15px;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >The California Code of Regulations defines a
                                &quot;resident&quot; as:</span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </div>
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                    margin-left: 20px;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >(1) every individual who is in the State of California
                        for other than a temporary or transitory purpose
                        and</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                    margin-left: 20px;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >(2) every individual who is domiciled in the State of
                        California who is outside the State of California for a
                        temporary or transitory purpose</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >All other individuals are defined as
                        &quot;non-residents.&quot;</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >If this definition of &quot;resident&quot; applies to
                        you, we must adhere to certain rights and obligations
                        regarding your personal information.</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >What categories of personal information do we
                          collect?</strong
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We have collected the following categories of personal
                        information in the past twelve (12) months:</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <table
                  style="
                    box-sizing: border-box;
                    border-collapse: collapse;
                    caption-side: bottom;
                    background: transparent !important;
                    width: 1133.61px;
                  "
                >
                  <tbody
                    style="
                      box-sizing: border-box;
                      border-width: 0px;
                      border-style: solid;
                      border-image: initial;
                      border-color: inherit;
                      background: transparent !important;
                    "
                  >
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><strong
                                style="
                                  box-sizing: border-box;
                                  font-weight: bolder;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Category</strong
                              ></span
                            ></span
                          ></span
                        ><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        />
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><strong
                                style="
                                  box-sizing: border-box;
                                  font-weight: bolder;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Examples</strong
                              ></span
                            ></span
                          ></span
                        ><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        />
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><strong
                                style="
                                  box-sizing: border-box;
                                  font-weight: bolder;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Collected</strong
                              ></span
                            ></span
                          ></span
                        ><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        /><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                        />
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >A. Identifiers</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Contact details, such as real name, alias,
                                postal address, telephone or mobile contact
                                number, unique personal identifier, online
                                identifier, Internet Protocol address, email
                                address and account name</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                          vertical-align: middle;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >B. Personal information categories listed in
                                the California Customer Records statute</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Name, contact information, education,
                                employment, employment history and financial
                                information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >C. Protected classification characteristics
                                under California or federal law</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Gender and date of birth</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >D. Commercial information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Transaction information, purchase history,
                                financial details and payment information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >E. Biometric information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Fingerprints and voiceprints</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >NO</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >F. Internet or other similar network
                                activity</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Browsing history, search history, online
                                behavior, interest data, and interactions with
                                our and other websites, applications, systems
                                and advertisements</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >NO</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><br
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  " /></span></span
                          ></span>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >G. Geolocation data</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Device location</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >NO</span
                              ></span
                            ></span
                          >
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >H. Audio, electronic, visual, thermal,
                                olfactory, or similar information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Images and audio, video or call recordings
                                created in connection with our business
                                activities</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >I. Professional or employment-related
                                information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Business contact details in order to provide
                                you our services at a business level, job title
                                as well as work history and professional
                                qualifications if you apply for a job with
                                us</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 166.883px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >J. Education Information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Student records and directory information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 0px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: inherit;
                          border-left-color: inherit;
                          background: transparent !important;
                          text-align: center;
                          width: 166.883px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr
                      style="
                        box-sizing: border-box;
                        position: relative;
                        z-index: 2;
                        border-width: 0px;
                        border-style: solid;
                        border-image: initial;
                        border-color: inherit;
                        background: transparent !important;
                      "
                    >
                      <td
                        style="
                          box-sizing: border-box;
                          border: 1px solid black;
                          background: transparent !important;
                          width: 383.125px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >K. Inferences drawn from other personal
                                information</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: black;
                          border-left-color: inherit;
                          background: transparent !important;
                          width: 582.602px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >Inferences drawn from any of the collected
                                personal information listed above to create a
                                profile or summary about, for example, an
                                individual&rsquo;s preferences and
                                characteristics</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                      <td
                        style="
                          box-sizing: border-box;
                          border-width: 1px 1px 1px 0px;
                          border-style: solid;
                          border-image: initial;
                          border-top-color: black;
                          border-right-color: black;
                          border-bottom-color: black;
                          border-left-color: inherit;
                          background: transparent !important;
                          text-align: center;
                          width: 166.883px;
                        "
                      >
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <br
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                            "
                          />
                        </div>
                        <div
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            line-height: 1.5;
                          "
                        >
                          <span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >YES</span
                              ></span
                            ></span
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >We may also collect other personal information outside
                        of these categories instances where you interact with us
                        in-person, online, or by phone or mail in the context
                        of:</span
                      ></span
                    ></span
                  >
                </div>
                <ul
                  style="
                    box-sizing: border-box;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background: transparent !important;
                  "
                >
                  <li
                    style="
                      box-sizing: border-box;
                      padding: 0px;
                      margin: 0px;
                      list-style: none;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Receiving help through our customer support
                          channels;</span
                        ></span
                      ></span
                    >
                  </li>
                </ul>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <br />
                </div>
                <ul
                  style="
                    box-sizing: border-box;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background: transparent !important;
                  "
                >
                  <li
                    style="
                      box-sizing: border-box;
                      padding: 0px;
                      margin: 0px;
                      list-style: none;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Participation in customer surveys or contests;
                          and</span
                        ></span
                      ></span
                    >
                  </li>
                </ul>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                  "
                >
                  <br />
                </div>
                <ul
                  style="
                    box-sizing: border-box;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background: transparent !important;
                  "
                >
                  <li
                    style="
                      box-sizing: border-box;
                      padding: 0px;
                      margin: 0px;
                      list-style: none;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Facilitation in the delivery of our Services and to
                          respond to your inquiries.</span
                        ></span
                      ></span
                    >
                  </li>
                </ul>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <span
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      font-size: 15px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        font-size: 15px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        ><strong
                          style="
                            box-sizing: border-box;
                            font-weight: bolder;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >How do we use and share your personal
                          information?</strong
                        ></span
                      ></span
                    ></span
                  >
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <br
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                    "
                  />
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    background: transparent !important;
                    line-height: 1.5;
                  "
                >
                  <div
                    style="
                      box-sizing: border-box;
                      background: transparent !important;
                      line-height: 1.5;
                    "
                  >
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >More information about our data collection and
                            sharing practices can be found in this privacy
                            notice.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >You may contact us&nbsp;by email at&nbsp;</span
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 15px;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >legal@acceleralia.com,&nbsp;</span
                            ></span
                          ></span
                        ></span
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >or by referring to the contact details at the bottom of
                        this document.</span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >If you are using an authorized agent to exercise
                            your right to opt-out we may deny a request if the
                            authorized agent does not submit proof that they
                            have been validly authorized to act on your
                            behalf.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Will your information be shared with anyone
                              else?</strong
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >We may disclose your personal information with our
                            service providers pursuant to a written contract
                            between us and each service provider. Each service
                            provider is a for-profit entity that processes the
                            information on our behalf.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >We may use your personal information for our own
                            business purposes, such as for undertaking internal
                            research for technological development and
                            demonstration. This is not considered to be
                            &quot;selling&quot; of your personal data.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >ACCELERALIA S.L.</span
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >&nbsp;has not disclosed or sold any personal
                            information to third parties for a business or
                            commercial purpose in the preceding 12
                            months.&nbsp;<span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  >ACCELERALIA S.L.</span
                                ></span
                              ></span
                            >
                            will not sell personal information in the future
                            belonging to website visitors, users and other
                            consumers.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><strong
                              style="
                                box-sizing: border-box;
                                font-weight: bolder;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Your rights with respect to your personal
                              data</strong
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><u
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Right to request deletion of the data - Request
                              to delete</u
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >You can ask for the deletion of your personal
                            information. If you ask us to delete your personal
                            information, we will respect your request and delete
                            your personal information, subject to certain
                            exceptions provided by law, such as (but not limited
                            to) the exercise by another consumer of his or her
                            right to free speech, our compliance requirements
                            resulting from a legal obligation or any processing
                            that may be required to protect against illegal
                            activities.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><u
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Right to be informed - Request to know</u
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Depending on the circumstances, you have a right to
                            know:</span
                          ></span
                        ></span
                      >
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >whether we collect and use your personal
                              information;</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >the categories of personal information that we
                              collect;</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >the purposes for which the collected personal
                              information is used;</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >whether we sell your personal information to
                              third parties;</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >the categories of personal information that we
                              sold or disclosed for a business purpose;</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >the categories of third parties to whom the
                              personal information was sold or disclosed for a
                              business purpose; and</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >the business or commercial purpose for collecting
                              or selling personal information.</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >In accordance with applicable law, we are not
                            obligated to provide or delete consumer information
                            that is de-identified in response to a consumer
                            request or to re-identify individual data to verify
                            a consumer request.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><u
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Right to Non-Discrimination for the Exercise of a
                              Consumer&rsquo;s Privacy Rights</u
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >We will not discriminate against you if you
                            exercise your privacy rights.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><u
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Verification process</u
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Upon receiving your request, we will need to verify
                            your identity to determine you are the same person
                            about whom we have the information in our system.
                            These verification efforts require us to ask you to
                            provide information so that we can match it with
                            information you have previously provided us. For
                            instance, depending on the type of request you
                            submit, we may ask you to provide certain
                            information so that we can match the information you
                            provide with the information we already have on
                            file, or we may contact you through a communication
                            method (e.g. phone or email) that you have
                            previously provided to us. We may also use other
                            verification methods as the circumstances
                            dictate.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >We will only use personal information provided in
                            your request to verify your identity or authority to
                            make the request. To the extent possible, we will
                            avoid requesting additional information from you for
                            the purposes of verification. If, however, we cannot
                            verify your identity from the information already
                            maintained by us, we may request that you provide
                            additional information for the purposes of verifying
                            your identity, and for security or fraud-prevention
                            purposes. We will delete such additionally provided
                            information as soon as we finish verifying
                            you.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><u
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >Other privacy rights</u
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >you may object to the processing of your personal
                              data</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >you may request correction of your personal data
                              if it is incorrect or no longer relevant, or ask
                              to restrict the processing of the data</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >you can designate an authorized agent to make a
                              request under the CCPA on your behalf. We may deny
                              a request from an authorized agent that does not
                              submit proof that they have been validly
                              authorized to act on your behalf in accordance
                              with the CCPA.</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                      "
                    >
                      <br />
                    </div>
                    <ul
                      style="
                        box-sizing: border-box;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        background: transparent !important;
                      "
                    >
                      <li
                        style="
                          box-sizing: border-box;
                          padding: 0px;
                          margin: 0px;
                          list-style: none;
                          background: transparent !important;
                          line-height: 1.5;
                        "
                      >
                        <span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              >you may request to opt-out from future selling of
                              your personal information to third parties. Upon
                              receiving a request to opt-out, we will act upon
                              the request as soon as feasibly possible, but no
                              later than 15 days from the date of the request
                              submission.</span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >To exercise these rights, you can contact
                            us&nbsp;</span
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 15px;
                            "
                            ><span
                              data-custom-class="body_text"
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  >by email
                                  at&nbsp;legal@acceleralia.com,&nbsp;</span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      ><span
                        data-custom-class="body_text"
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(89, 89, 89) !important;
                          font-size: 14px !important;
                          font-family: Poppins !important;
                        "
                        >or by referring to the contact details at the bottom of
                        this document. If you have a complaint about how we
                        handle your data, we would like to hear from you.</span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(127, 127, 127);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(0, 0, 0);
                                "
                                ><strong
                                  style="
                                    box-sizing: border-box;
                                    font-weight: bolder;
                                    background: transparent !important;
                                  "
                                  ><span
                                    data-custom-class="heading_1"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      font-family: Poppins !important;
                                      font-size: 19px !important;
                                      color: rgb(0, 0, 0) !important;
                                    "
                                    >12. DO WE MAKE UPDATES TO THIS
                                    NOTICE?</span
                                  ></strong
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <em
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                        ><br
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                          "
                      /></em>
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><em
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                              "
                              ><strong
                                style="
                                  box-sizing: border-box;
                                  font-weight: bolder;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >In Short:&nbsp;</strong
                              >Yes, we will update this notice as necessary to
                              stay compliant with relevant laws.</em
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >We may update this privacy notice from time to
                            time. The updated version will be indicated by an
                            updated &quot;Revised&quot; date and the updated
                            version will be effective as soon as it is
                            accessible. If we make material changes to this
                            privacy notice, we may notify you either by
                            prominently posting a notice of such changes or by
                            directly sending you a notification. We encourage
                            you to review this privacy notice frequently to be
                            informed of how we are protecting your
                            information.</span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(127, 127, 127);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(0, 0, 0);
                                "
                                ><strong
                                  style="
                                    box-sizing: border-box;
                                    font-weight: bolder;
                                    background: transparent !important;
                                  "
                                  ><span
                                    data-custom-class="heading_1"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      font-family: Poppins !important;
                                      font-size: 19px !important;
                                      color: rgb(0, 0, 0) !important;
                                    "
                                    >13. HOW CAN YOU CONTACT US ABOUT THIS
                                    NOTICE?</span
                                  ></strong
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >If you have questions or comments about this
                            notice, you may&nbsp;<span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                data-custom-class="body_text"
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89) !important;
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                >email us at&nbsp;legal@acceleralia.com</span
                              ></span
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89);
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 15px;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  data-custom-class="body_text"
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89) !important;
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  >&nbsp;or by post to:</span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(89, 89, 89);
                                  font-size: 14px !important;
                                  font-family: Poppins !important;
                                "
                                ><span
                                  style="
                                    box-sizing: border-box;
                                    background: transparent !important;
                                    color: rgb(89, 89, 89);
                                    font-size: 14px !important;
                                    font-family: Poppins !important;
                                  "
                                  ><span
                                    data-custom-class="body_text"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      color: rgb(89, 89, 89) !important;
                                      font-size: 14px !important;
                                      font-family: Poppins !important;
                                    "
                                    >ACCELERALIA S.L.</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >CALLE CAN BRUIXA, 16 - P. ENT PTA. 1</span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Barcelona<span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89);
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                color: rgb(89, 89, 89) !important;
                                font-size: 15px;
                                font-family: Poppins !important;
                              "
                              >,&nbsp;Catalu&ntilde;a&nbsp;08028</span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                        "
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >Spain</span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          color: rgb(127, 127, 127);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89);
                            font-size: 15px;
                          "
                          ><span
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              font-size: 15px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                box-sizing: border-box;
                                background: transparent !important;
                                font-size: 15px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                style="
                                  box-sizing: border-box;
                                  background: transparent !important;
                                  color: rgb(0, 0, 0);
                                "
                                ><strong
                                  style="
                                    box-sizing: border-box;
                                    font-weight: bolder;
                                    background: transparent !important;
                                  "
                                  ><span
                                    data-custom-class="heading_1"
                                    style="
                                      box-sizing: border-box;
                                      background: transparent !important;
                                      font-family: Poppins !important;
                                      font-size: 19px !important;
                                      color: rgb(0, 0, 0) !important;
                                    "
                                    >14. HOW CAN YOU REVIEW, UPDATE, OR DELETE
                                    THE DATA WE COLLECT FROM YOU?</span
                                  ></strong
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <br
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                        "
                      />
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        background: transparent !important;
                        line-height: 1.5;
                      "
                    >
                      <span
                        style="
                          box-sizing: border-box;
                          background: transparent !important;
                          font-size: 15px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            font-size: 15px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >Based on the applicable laws of your country, you
                            may have the right to request access to the personal
                            information we collect from you, change that
                            information, or delete it in some circumstances. To
                            request to review, update, or delete your personal
                            information, please&nbsp;</span
                          ><span
                            data-custom-class="body_text"
                            style="
                              box-sizing: border-box;
                              background: transparent !important;
                              color: rgb(89, 89, 89) !important;
                              font-size: 14px !important;
                              font-family: Poppins !important;
                            "
                            >visit:&nbsp;<a
                              data-custom-class="link"
                              href="https://app.acceleralia.com/contact-us/"
                              style="
                                box-sizing: border-box;
                                color: rgb(48, 48, 241) !important;
                                text-decoration: none !important;
                                background: transparent !important;
                                font-size: 14px !important;
                                font-family: Poppins !important;
                                word-break: break-word !important;
                              "
                              target="_blank"
                              >https://app.acceleralia.com/contact-us/</a
                            ></span
                          ></span
                        ><span
                          data-custom-class="body_text"
                          style="
                            box-sizing: border-box;
                            background: transparent !important;
                            color: rgb(89, 89, 89) !important;
                            font-size: 14px !important;
                            font-family: Poppins !important;
                          "
                          >. We will respond to your request within 30
                          days.</span
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
