<template>
  <div class="row chapter">
    <div class="col-1 relative">
      <button
        @click="show = !show"
        :class="[
          { 'new-template-separator-opened': show },
          { 'new-template-separator-closed': !show },
          { 'new-templeate-error-border': checkErrorHere() },
          'new-templeate-close new-templeate-close-absolute',
        ]"
      />
    </div>
    <div class="col-10 new-template-data new-template-first-margin">
      <div>
        <hover :content="langFilter('addTheme')">
          <button @click="addTheme" class="new-btn-purple-add" />
        </hover>
      </div>
      <span class="new-template-nowrap"
        >{{ langFilter("theme") }} {{ themeIndex + 1 }}.*</span
      >
      <input
        @input="updateTheme($event, 1)"
        :class="[
          { 'new-template-error-input': checkedError && theme.title === '' },
          'new-template-input-part',
        ]"
        :placeholder="langFilter('themeName') + '...'"
        type="text"
        :value="theme.title"
      />
    </div>
    <new-template-creation-options
      @openModalConfirmation="openModalConfirmation($event)"
      class="new-template-first-margin"
      :lang="lang"
      :index="themeIndex"
      :type="1"
    />
    <div class="col-1" />
    <div class="col-10 new-template-description">
      <textarea
        @input="updateTheme($event, 2)"
        class="new-template-input-description"
        :placeholder="langFilter('desription') + '...'"
        type="text"
        :value="theme.description"
      />
    </div>

    <div v-show="show">
      <draggable
        v-model="this.$parent.$parent.themes[themeIndex].aparts"
        draggable=".part"
        handle=".dragg-me-part"
        animation="500"
        ghostClass="ghost"
        chosenClass="chosen"
        :options="{
          animation: 500,
          handle: '.dragg-me-part',
          ghostClass: 'ghost',
          chosenClass: 'chosen',
          group: 'part',
          itemKey: 'id',
        }"
      >
        <new-template-creation-apart
          v-for="(apart, apartIndex) in theme.aparts"
          :key="apartIndex"
          @openModalConfirmation="openModalConfirmation($event)"
          @addApart="addApart(apartIndex)"
          @updateApart="updateApart($event)"
          @addQuestion="addQuestion($event)"
          @updateQuestion="updateQuestion($event)"
          @checkError="checkError($event)"
          :checkedError="checkedError"
          :apart="apart"
          :apartIndex="apartIndex"
          :themeIndex="themeIndex"
          :lang="lang"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
import { VueDraggableNext } from "vue-draggable-next";
export default {
  props: ["theme", "themeIndex", "lang", "checkedError"],
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      show: false,
      traducciones: [
        {
          name: "theme",
          es: "Tema",
          en: "Theme",
        },
        {
          name: "themeName",
          es: "Escribir nombre del Tema...",
          en: "Write the name of the Theme...",
        },
        {
          name: "desription",
          es: "Descripción...",
          en: "Description...",
        },
        {
          name: "addTheme",
          es: "Añadir Tema",
          en: "Add Theme",
        },
      ],
    };
  },
  methods: {
    openModalConfirmation(data) {
      data.push(this.themeIndex);
      this.$emit("openModalConfirmation", data);
    },
    updateTheme(event, type) {
      this.$emit("checkError", false);
      this.$emit("updateTheme", [event.target.value, this.themeIndex, type]);
    },
    checkError(err) {
      this.$emit("checkError", err);
    },
    addApart(index) {
      this.$emit("addApart", [this.themeIndex, index]);
    },
    updateApart(data) {
      data.push(this.themeIndex);
      this.$emit("updateApart", data);
    },
    addQuestion(data) {
      data.push(this.themeIndex);
      this.$emit("addQuestion", data);
    },
    updateQuestion(data) {
      data.push(this.themeIndex);
      this.$emit("updateQuestion", data);
    },
    addTheme() {
      this.$emit("addTheme", this.themeIndex);
    },
    checkErrorHere() {
      if (this.checkedError) {
        const checker = this.theme.aparts.map((apart) => {
          if (apart.title === "") {
            return true;
          }
          apart.questions.map((question) => {
            if (question.title === "") {
              return true;
            }
          });
          return false;
        });
        if (checker.includes(true)) return true;
      }
      return false;
    },
  },
  mixins: [translationMixin],
};
</script>
