<template>
  <div class="no-selection-itinerary" id="header" style="padding-left: 3.5%">
    <td nowrap>
      <img class="logo-header" :src="src" />
      <h1
        style="padding-bottom: 4px"
        class="css-titulo-header margin-title-header"
      >
        {{ title }}
      </h1>
    </td>
    <div style="margin-bottom: 70px"></div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "title", "src"],
  name: "ItineraryHeader",
  data() {
    return {
      traducciones: [
        {
          name: "itinerario",
          es: "itinerario",
          en: "itinerary",
        },
      ],
    };
  },
  mixins: [translationMixin],
};
</script>

<style scoped></style>
