<template>
  <div>
    <div
      id="contenido"
      v-bind:class="{
        'div-princ': true,
        'margin-404': true,
        'header-error': this.header,
      }"
    >
      <div v-html="image"></div>
      <div id="error">
        <div>
          <h1 v-html="langFilter(title)" class="h1-error"></h1>
          <p v-html="langFilter(body)" class="p-error"></p>
        </div>
        <div>
          <img class="vector" id="vector" :src="vector" />
        </div>
        <div>
          <div class="button-noautorizado">
            <button
              @click="btnclick()"
              v-html="langFilter(vamos)"
              class="button-error"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
export default {
  props: ["image", "vector", "title", "link", "body", "vamos", "header"],
  data() {
    return {
      lang: "",
      traducciones: [
        {
          name: "404-title",
          es: "Error 404",
          en: "Error 404",
        },
        {
          name: "404-body",
          es: "Ha habido un error, clica aquí<br>para volver a la página principal.",
          en: "There has been an error, click here<br>to return to the main page.",
        },
        {
          name: "500-title",
          es: "Error 500",
          en: "Error 500",
        },
        {
          name: "500-body",
          es: "Ha habido un error, clica aquí<br>para volver a la página principal.",
          en: "There has been an error, click here<br>to return to the main page.",
        },
        {
          name: "link-redirect",
          es: "https://app.acceleralia.com",
          en: "https://app.acceleralia.com",
        },
        {
          name: "404-vamos",
          es: "¡Vamos!",
          en: "¡Let's go!",
        },
        {
          name: "permiso-title",
          es: "Whoops!",
          en: "Whoops",
        },
        {
          name: "permiso-body",
          es: "Parece que no estás autorizado<br>para acceder a la siguiente página...",
          en: "It seems you are not authorised<br>to access this page...",
        },

        {
          name: "registered-body",
          es: "Parece que el usuario ya esta registardo,<br> por favor inicia sesión para acceder",
          en: "It seems the user is already registered,<br> please login to access",
        },
        {
          name: "noSpaces-body",
          es: "Parece que no tienes ningún espacio asociado, <br> por favor contacta con tu administrador.",
          en: "It seems you have no spaces associated, <br> please contact your administrator.",
        },
        {
          name: "permiso-volver",
          es: "Volver",
          en: "Back",
        },
        {
          name: "session-title",
          es: "Tu sesión ha<br> expirado",
          en: "Your session has<br> expired",
        },
        {
          name: "session-body",
          es: "Por favor, inicia sesión nuevamente<br> para continuar.",
          en: "<br>Please log in again to continue.",
        },
        {
          name: "session-volver",
          es: "Iniciar sesión",
          en: "Log in",
        },
        {
          name: "no-programs",
          es: "Parece que no tienes proyectos asociados en este programa.",
          en: "It seems you don't have any projects associated with this program.",
        },
        {
          name: "logout",
          es: "https://app.acceleralia.com/logout",
          en: "https://app.acceleralia.com/logout",
        },
      ],
    };
  },
  created() {
    $(".myProjects-SecondHeaderContainer").hide();
    $(".carga").hide();
    $(".myProjects-MainContainer").show();

    const lang = localStorage.getItem("lang");
    if (lang == null) {
      axios.get(`${process.env.VUE_APP_API_URL}/getLang`).then((response) => {
        this.lang = response.data;
        localStorage.setItem("lang", this.lang);
      });
    } else {
      this.lang = lang;
    }
    // this.cambiarIdioma(window.location.href);
  },
  methods: {
    btnclick() {
      if (this.header == "true") {
        window.history.go(-1);
      } else {
        window.location.href = this.langFilter(this.link);
      }
    },
    // cambiarIdioma(url) {
    //   if (url.slice(-2) == "es") {
    //     this.lang = "es";
    //   }
    // },
  },

  mixins: [translationMixin],
};
</script>
