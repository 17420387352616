<template>
  <div class="itineray-header">
    <div class="row">
      <div class="col-2 itinerary-btn-title">
        <button
          class="itineray-header-button"
          @click="titlesOpened = !titlesOpened"
        >
          <img
            :src="
              require(`@/assets/img/newitinerary/${
                titlesOpened ? 'arrow-down' : 'arrow'
              }.svg`)
            "
            alt="arrow"
          />
        </button>
      </div>
      <div class="col-8">
        <input
          v-if="editing"
          @input="changeProjectName($event.target.value)"
          class="itinerary-title-input"
          type="text"
          :value="project.name"
        />
        <p v-else>
          {{ project.name }}
        </p>
      </div>
      <div
        v-if="view === 0 && space.id !== 22680"
        class="col-2 itinerary-edition-container"
      >
        <button v-if="editing" @click="save()" class="itinerary-save-btn">
          <img
            :src="require(`@/assets/img/newitinerary/save.svg`)"
            alt="save"
          />
        </button>
        <div class="toggle-itinerary">
          <div
            @click="setEditing()"
            :class="`editon-button ${
              editing ? 'itinerary-button-editing' : 'flex-start'
            }`"
          >
            <div />
          </div>
          <span>{{ langFilter("editionMode") }}</span>
        </div>
      </div>
      <div class="col-3" />
      <div class="col-5 new-itinerary-loading-header-container">
        <div class="new-itinerary-loading-header">
          <div :style="{ width: percentage + '%' }" />
        </div>
        <span v-if="percentage >= 100">100</span>
        <span v-else>{{ percentage }}</span>
        %
      </div>
    </div>
    <search-project
      v-if="titlesOpened"
      @selectProject="selectProject($event)"
      :lang="lang"
      :projects="allProjects"
    />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "space", "editing", "project", "view", "percentage"],
  data() {
    return {
      titlesOpened: false,
      allProjects: [],
      traducciones: [
        {
          name: "title_template_screen",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "editionMode",
          es: "Modo edición",
          en: "Edition mode",
        },
      ],
    };
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/getAllProjectsBySpace/${this.space.id}`
      )
      .then((response) => {
        this.allProjects = response.data;
      });
  },
  methods: {
    changeProjectName(name) {
      this.$emit("changeProjectName", name);
    },
    setEditing() {
      this.$emit("setEditing");
    },
    save() {
      this.$emit("saveItinerary");
    },
    selectProject(id) {
      this.titlesOpened = false;
      this.$emit("selectProject", id);
    },
  },
  mixins: [translationMixin],
};
</script>
