<style>
.forgot-password-account-msg {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;

  text-align: center;

  color: #ffffff;
}
.forgot-password-account-msg span {
  color: #815bf0;
  cursor: pointer;
}
.forgot-password-final-text {
  width: 607px;
  font-family: "Poppins-Regular";
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #e5e6fa;
}
</style>
<template>
  <div>
    <div class="login-sidebar">
      <img
        class="login-sidebar-logo"
        :src="require('@/assets/img/sidebar/acc_logo.png')"
      />
      <p class="login-sidebar-text">
        {{ langFilter("Set your") }}
        <span>{{ langFilter("password") }}</span>
      </p>
    </div>
    <div class="login-main-container" v-if="steps == 1">
      <h1 class="login-header">
        {{ langFilter("Recover your password.") }}
      </h1>
      <h1 class="login-second-header">
        {{ langFilter("Set your") }}
        <span>{{ langFilter("new password") }}</span
        >.
      </h1>
      <div class="register-input-row" style="margin-top: 110px">
        <div
          class="register-input-col"
          style="margin-left: 75px; margin-right: 75px"
        >
          <div class="register-input-row">
            <label>{{ langFilter("New Password") }}</label>
            <input
              type="password"
              id="input-password"
              :placeholder="langFilter('New Password')"
              v-model="password"
            />
          </div>
          <div class="register-input-row">
            <label>{{ langFilter("Repeat Password") }}</label>
            <input
              type="password"
              id="input-repeat"
              :placeholder="langFilter('Repeat Password')"
              v-model="repeat"
            />
          </div>
        </div>
      </div>

      <div class="register-input-row" style="margin-top: 45px">
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px">
          <div class="register-input-col"></div>
          <div class="register-input-col">
            <div class="login-button">
              <p
                class="login-button-text"
                v-if="steps == 1"
                @click="passwordsValidation"
              >
                {{ langFilter("Save") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-main-container" v-if="steps == 2">
      <h1 class="login-header">
        {{ langFilter("Recover your password.") }}
      </h1>
      <h1 class="login-second-header">
        {{ langFilter("Set your") }}
        <span>{{ langFilter("new password") }}.</span>
      </h1>
      <div class="register-input-row" style="margin-top: 128px">
        <div class="register-input-col">
          <p class="forgot-password-final-text">
            {{
              langFilter(
                "The new password has been set, now you can log in to the platform."
              )
            }}
          </p>
        </div>
      </div>

      <div class="register-input-row" style="margin-top: 45px">
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px"></div>
        <div class="register-input-col" style="width: 325px">
          <div class="register-input-col">
            <div class="login-button" @click="goLogin()">
              <p class="login-button-text">
                {{ langFilter("Log in") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <alertMessage :lang="lang" ref="alerts"></alertMessage>
  </div>
</template>
<script>
import translationMixin from "../mixins/translationMixin.js";
import axios from "axios";

export default {
  props: ["email", "token"],

  data() {
    return {
      // alerts
      errors: [],
      succesful: [],
      //data
      lang:'es',
      steps: 1,
      password: "",
      repeat: "",
      traducciones: [
        {
          name: "Recover your password.",
          es: "Reestablece tu contraseña",
          en: "Recover your password.",
        },
        {
          name: "Set your",
          es: "Establece tu",
          en: "Set your",
        },
        {
          name: "new password",
          es: "nueva contraseña",
          en: "new password",
        },
        {
          name: "New Password",
          es: "Nueva Contraseña",
          en: "New Password",
        },
        {
          name: "Repeat Password",
          es: "Repetir Contraseña",
          en: "Repeat Password",
        },
        {
          name: "Save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "Log in",
          es: "Iniciar sesión",
          en: "Log in",
        },
        {
          name: "password",
          es: "contraseña",
          en: "password",
        },

        {
          name: "The new password has been set, now you can log in to the platform.",
          es: "Se ha establecido tu nueva contraseña, ahora puedes iniciar sesion en la plataforma.",
          en: "The new password has been set, now you can log in to the platform.",
        },
        {
          name: "password",
          es: "contraseña",
          en: "password",
        },

        {
          name: "The new password has been set, now you can log in to the platform.",
          es: "Se ha establecido tu nueva contraseña, ahora puedes iniciar sesion en la plataforma.",
          en: "The new password has been set, now you can log in to the platform.",
        },
      ],
    };
  },
  created() {
    this.lang = navigator.language.substring(0, 2);
  },
  computed: {},
  methods: {
    passwordsValidation() {
      if (this.password == this.repeat && this.password.length !== 0) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/recover-password/reset-password`, {
            email: this.email,
            token: this.token,
            password: this.password,
          })
          .then((response) => {
            if(response.data==false){
              this.$router.replace({ name: "Forgot" });
            }
            this.steps = 2;
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
      } else {
        if (this.password !== this.repeat) {
          this.$refs.alerts.title = "Ups... Something went wrong!";
          this.$refs.alerts.text = "The passwords you entered do not match.";
        }
        if (this.password.length == 0) {
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
        }
        this.openErrorsAlert();
      }
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    goLogin() {
      window.location.href = "/login/" + this.lang;
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
  },
  mixins: [translationMixin],
};
</script>
