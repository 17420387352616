<template>
  <div @click="closeModal" class="modal-general-mask">
    <div class="modal-general-wrapper">
      <div class="modal-send-correction-container div-center">
        <div class="modal-send-correction-header">
          <p class="modal-send-correction-close-btn">x</p>
        </div>
        <div class="modal-send-correction-body">
          <p>
            <span v-if="role == 1">
              {{ langFilter("sendCorrection") }}
            </span>
            <span v-else>
              {{ langFilter("sendToCorrect") }}
            </span>
          </p>
        </div>
        <div class="modal-send-correction-footer">
          <div class="modal-send-correction-footer-buttons">
            <button
              class="modal-send-correction-cancel-button"
              @click="closeModal"
            >
              {{ langFilter("cancel") }}
            </button>
            <button
              class="modal-send-correction-footer-button"
              @click="sendCorrection"
            >
              {{ langFilter("send") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: [
    "lang",
    "project_id",
    "station_id",
    "solution_id",
    "solution_name",
    "role",
    "space_id",
  ],
  data() {
    return {
      traducciones: [
        {
          name: "sendCorrection",
          es: "Los miembros del proyecto recibirán tus comentarios.",
          en: "Members of the project will receive your feedback.",
        },
        {
          name: "sendToCorrect",
          es: "El tutor recibirá la solicitud de revisión del proyecto",
          en: "The tutor will receive the request to review the project",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "send",
          es: "Sí, continuar",
          en: "Yes, continue",
        },
      ],
    };
  },
  methods: {
    closeModal(el) {
      if (
        el.target.className == "modal-general-wrapper" ||
        el.target.className == "modal-send-correction-cancel-button" ||
        el.target.className == "modal-send-correction-close-btn"
      ) {
        this.$emit("closeModal", false);
      }
    },
    sendCorrection() {
      this.$emit("closeModal", true);
      axios.post(`${process.env.VUE_APP_API_URL}/solution/sendCorrection`, {
        project_id: this.project_id,
        station_id: this.station_id,
        solution_id: this.solution_id,
        space_id: this.space_id,
        role: this.role,
      });
    },
  },
  mixins: [translationMixin],
};
</script>
