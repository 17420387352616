<template>
  <div class="newTemplate-template-containerPrinc">
    <div class="newTemplate-template-container">
      <div
        :style="`background-image: url('${myImage}')`"
        class="newTemplate-template-header"
      />
      <div class="newTemplate-template-body">
        <p class="newTemplate-template-title">
          {{ title }}
        </p>
        <p class="newTemplate-template-data">
          {{ langFilter("create") }}:
          <span class="newTemplate-purple-txt">{{ name }} {{ surname }}</span>
        </p>
        <div class="newTemplate-template-data">
          <span v-if="categories.length === 1"
            >{{ langFilter("category") }}:
          </span>
          <span v-else>{{ langFilter("categories") }}:</span>
          <span
            v-for="(category, caregoryIndex) in categories"
            :key="caregoryIndex"
            class="newTemplate-purple-txt"
          >
            <span
              v-if="
                categories.length !== 1 &&
                caregoryIndex !== categories.length - 1
              "
              >{{ category.name }},</span
            >
            <span v-else>{{ category.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: ["lang", "title", "image", "categories"],
  data() {
    return {
      name: "Name",
      surname: "Surname",
      myImage: this.getImage(),
      traducciones: [
        {
          name: "create",
          es: "Creado por",
          en: "Created by",
        },
        {
          name: "category",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "categories",
          es: "Categorías",
          en: "Categories",
        },
      ],
    };
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/getLogedUserData`)
      .then((response) => {
        this.name = response.data.first_name;
        this.surname = response.data.last_name;
      });
  },
  methods: {
    getImage() {
      if (this.image == "/img/track/predefined.png") {
        return this.image;
      } else {
        if (this.image.includes("track_headers"))
          return process.env.VUE_APP_API_STORAGE + this.image;
        return (
          process.env.VUE_APP_API_STORAGE + "/img/track_headers/" + this.image
        );
      }
    },
  },
  watch: {
    image: function (newVal) {
      this.myImage = process.env.VUE_APP_API_STORAGE + newVal;
    },
  },
  mixins: [translationMixin],
};
</script>
