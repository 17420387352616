<template>
  <div>
    <tongue @reloadHome="reloadHome" :lang="lang" :space="space" />
    <div class="sidebar" :style="closed ? 'width:73px' : 'width:227px'">
      <div class="top-of-sidebar">
        <router-link to="/home">
          <img
            :src="require('@/assets/img/sidebar/acc_logo.png')"
            class="acceleralia-sidebar-logo pointer"
            v-show="!closed"
          />
          <img
            :src="require('@/assets/img/sidebar/logo_acceleralia_mini.svg')"
            class="acceleralia-sidebar-logo-mini pointer"
            v-show="closed"
          />
        </router-link>
        <div class="collapse-sidebar-button" @click="closeSidebar()">
          <img
            :src="require('@/assets/img/sidebar/sidebar_collapse_arrow.svg')"
            :style="closed ? 'rotate: 0deg;' : 'rotate: 180deg;'"
          />
        </div>
      </div>
      <sidebar-left
        @space="changeSpace($event)"
        :spaces="space"
        :spaceSelecteds="spaceSelected"
        :page="page"
        :lang="lang"
        class="left-of-sidebar"
      />
      <sidebar-right
        class="right-of-sidebar"
        :space="spaceSelected"
        :lang="lang"
        :page="page"
        :role="role"
      />
    </div>
    <div class="project-name-container">
      <p class="project-name" id="project-name"></p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["space", "role", "lang"],
  data() {
    return {
      spaceSelected: [],
      closed: false,
      page: 0,
      options: [
        {
          name: "Home",
          img: "icon-home",
          url: "home",
          section_number: "0",
        },
        {
          name: "Programs",
          img: "icon-programs",
          url: "programs",
          section_number: "1",
        },
        {
          name: "Projects",
          img: "icon–projects",
          url: "my-projects",
          section_number: "2",
        },
        {
          name: "Itinerary",
          img: "icon-itinerary",
          url: "tracks",
          section_number: "3",
        },
        {
          name: "Forum",
          img: "icon-forum",
          url: "forum",
          section_number: "4",
        },
      ],
    };
  },
  created() {
    this.checkRoute(this.$route.name);
    this.spaceSelected = this.space[0];
    const sidebar_closed = localStorage.getItem("sidebar_closed");
    if (sidebar_closed == "true") {
      this.closed = true;
    }
  },
  mounted() {
    if (this.closed) {
      $(".div-general-menu-lateral").addClass("container-close-sidebar");
    }
  },
  methods: {
    checkRoute(route) {
      switch (route) {
        case "Home ":
          this.page = 0;
          break;
        case "Programs":
          this.page = 1;
          break;
        case "MyProjects":
          this.page = 2;
          break;
        case "NewProject":
          this.page = 2;
          break;
        case "Templates":
          this.page = 3;
          break;
        case "NewTemplate":
          this.page = 3;
          break;
        case "Forum":
          this.page = 4;
          break;
        case "Faqs":
          this.page = 50;
          break;
        default:
          this.page = 100;
          break;
      }
    },
    reloadHome() {
      this.$emit("reloadHomeNotifications");
    },
    closeSidebar() {
      this.closed = !this.closed;
      localStorage.setItem("sidebar_closed", this.closed);
      if (this.closed) {
        $(".div-general-menu-lateral").addClass("container-close-sidebar");
      } else {
        $(".div-general-menu-lateral").removeClass("container-close-sidebar");
      }
    },
    changeSpace() {
      this.page = 0;
      this.$emit("changeSpace", this.space);
    },
  },
  watch: {
    $route(to) {
      this.checkRoute(to.name);
    },
  },
};
</script>
