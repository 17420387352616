<style lang="scss" scoped>
@import "../../../../src/assets/css/template/new-template.css";
</style>
<template>
  <div>
    <!-- <confirmation :lang="lang" ref="modalConfirmation2" /> -->
    <header-general
      :title="langFilter('title_template_screen')"
      :src="require('@/assets/img/header/template_logo.svg')"
    />
    <img
      v-if="loading"
      class="loading-center"
      :src="require('@/assets/img/general/loading.gif')"
    />
    <div v-show="!loading">
      <div
        style="width: 10px"
        class="back-arrow-solution back-arrow-templates"
        @click="clickBackArrow"
      >
        <img :src="require('@/assets/img/general/new_back.svg')" alt="back" />
      </div>
      <template-steps
        @selectStep="selectStep($event)"
        :stepNum="stepNum"
        :lang="lang"
      />
      <div v-show="stepNum < 3">
        <template-container
          @setTitle="setTitle($event)"
          @enableNext="enableNext($event)"
          @setTemplateImage="setTemplateImage($event)"
          @setCategories="setCategories($event)"
          @errorFileUpload="errorFileUpload()"
          :stepNum="stepNum"
          :lang="lang"
          :title="title"
          :categories="categories"
          :image="image"
        />
        <new-template-footer
          @previousStep="previousStep"
          @setNextStep="setNextStep"
          :stepNum="stepNum"
          :lang="lang"
          :nextStep="nextStep"
        />
      </div>
      <new-template-creation
        v-show="stepNum === 3"
        @updateTitle="setTitle($event)"
        @setLoading="setLoading($event)"
        @openAlert="openAlert($event)"
        ref="templateCreation"
        :lang="lang"
        :title="title"
        :image="image"
        :categories="categories"
        :space="space"
        :draft="draft"
        :relations="relations"
        :draftResources="draftResources"
      />
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import translationMixin from "../../../mixins/translationMixin.js";
export default {
  props: ["lang", "space"],
  data() {
    return {
      stepNum: 0,
      nextStep: false,
      title: "",
      categories: [],
      draft: false,
      image: "/img/track/predefined.png",
      relations: [],
      draftResources: [],
      loading: true,
      traducciones: [
        {
          name: "title_template_screen",
          es: "Plantillas",
          en: "Templates",
        },
      ],
    };
  },

  created() {
    if (this.$route.params.template_id) {
      const template_id = this.$route.params.template_id;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getTemplate/${this.space.id}/${template_id}/${this.lang}`
        )
        .then((response) => {
          const template = response.data.template;
          const categories = response.data.categories;
          const questions = response.data.questions;
          this.title = template.name;
          this.image = template.img;
          this.categories = categories;
          this.stepNum = 3;
          this.draft = template.draft === 1 ? true : false;
          let resources = response.data.resources;
          resources.forEach((resource) => {
            if (!resource.link.includes("http"))
              resource.link = `${process.env.VUE_APP_API_STORAGE}${resource.link}`;
          });
          this.draftResources = resources;
          this.relations = [response.data.relations, questions, resources];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "NewTemplate" });
          } else if (error.response.status === 401) {
            alert("No tienes permisos para acceder a esta página");
          } else {
            alert("Error al cargar el template");
          }
          this.$router.replace({ name: "Templates" });
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    confirmTrackContinue() {
      this.confirmTrackCancel();
      this.$router.push({ path: "/templates" });
    },
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },
    clickBackArrow() {
      if (
        window.confirm(
          "¿Estas seguro que quieres salir? Se perderán los datos no guardados."
        )
      ) {
        this.$router.push({ path: "/templates" });
      }
    },
    exitScreen() {
      //   const state = this.$refs.templateCreation.draftCompleted;
      //   if (state) this.$refs.templateCreation.saveTemplate(true, true);
      //   return state;
    },
    openAlert(event) {
      this.$emit("openAlert", event);
    },
    setLoading(event) {
      this.loading = event;
    },
    checkIfStepCompleted(stepNum) {
      if (stepNum === 1 || stepNum === 0 || stepNum == "-1") {
        return this.title !== "";
      } else if (stepNum === 2) {
        return this.categories.length !== 0 && this.title !== "";
      }
    },
    selectStep(stepIndex) {
      if (stepIndex < 3 && this.title === "") {
        this.nextStep = false;
      } else {
        if (this.stepNum <= stepIndex) {
          if (this.checkIfStepCompleted(stepIndex - 1)) {
            this.stepNum = stepIndex;
            this.nextStep = this.checkIfStepCompleted(stepIndex);
          }
        } else {
          this.stepNum = stepIndex;
          this.nextStep = true;
        }
      }
    },
    setTitle(event) {
      this.title = event;
    },
    previousStep() {
      this.stepNum--;
      this.nextStep = true;
    },
    setNextStep() {
      if (this.checkIfStepCompleted(this.stepNum)) {
        this.stepNum++;
        this.checkIfNextStepCompleted();
      } else {
        if (this.checkIfNextStepCompleted()) {
          this.nextStep = false;
        }
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    enableNext(event) {
      this.nextStep = event;
    },
    checkIfNextStepCompleted() {
      if (this.stepNum === 0 || this.stepNum === 1) {
        this.nextStep = this.title !== "";
      } else if (this.stepNum === 2) {
        this.nextStep = this.categories.length > 0;
      } else {
        this.nextStep = false;
      }
    },
    setTemplateImage(event) {
      this.image = event;
    },
    setCategories(event) {
      this.categories = event;
      this.nextStep = this.categories.length > 0;
    },
    errorFileUpload() {
      this.$refs.alerts.title = "Incorrect data entered!";
      this.$refs.alerts.text = "the email and/or password do not match.";
      console.log("error file upload");
      this.openErrorsAlert();
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
  },
  mixins: [translationMixin],
};
</script>
