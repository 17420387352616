<template>
  <div>
    <div
      id="notification_table_center"
      v-if="!banner"
      class="notifications-table center relative"
      :class="{
        'notfications-banner-width': banner,
        'notfications-normal-width': banner == banner,
      }"
    >
      <notifications-table-header
        @changeNot="changeNot($event)"
        @openModal="openModal($event)"
        @locateModal="locateModal($event, banner)"
        :newNotifications="newNotifications"
        :lang="lang"
        :banner="banner"
        :space_id="space_id"
      />
      <img
        id="loading_table"
        :class="{
          'carga-not': banner == undefined || banner == false,
          'carga-not-banner': banner == true,
        }"
        v-if="initialLoad"
        :src="require('@/assets/img/general/carga.gif')"
      />
      <div v-else-if="notifications.length != 0">
        <div id="notifications_table">
          <notifications-table-new
            v-show="newNotifications.length != 0"
            @openModal="openModal($event)"
            @locateModal="locateModal($event, banner)"
            :notifications="newNotifications"
            :banner="banner"
            :lang="lang"
          />
          <notifications-table-previous
            v-show="changeNotification == 0 && prevNotifications.length != 0"
            @openModal="openModal($event)"
            @locateModal="locateModal($event, banner)"
            :notifications="prevNotifications"
            :lang="lang"
          />
          <div v-if="loading" class="align-center">
            <img
              class="view-more-load"
              :src="require('@/assets/img/general/carga.gif')"
            />
          </div>
          <div v-if="loading && loadNotifications == []" class="align-center">
            <img
              class="view-more-load"
              :src="require('@/assets/img/general/carga.gif')"
            />
          </div>
          <div
            @click="showMoreNotifications"
            v-if="countNotifications > 0 && loading == false"
            class="view-more-div"
          >
            <span
              v-show="changeNotification == 0"
              id="view-more"
              class="view-more"
              >{{ langFilter("view_more") }}</span
            >
          </div>
        </div>
      </div>
      <div v-else class="no-notifications">
        {{ langFilter("no_notifications") }}
      </div>
    </div>
    <div v-if="banner">
      <div class="notifications-modal-arrow"></div>
      <div
        class="notifications-table center"
        :class="{
          'notifications-modal-width': banner,
          'notfications-normal-width': banner == banner,
        }"
      >
        <notifications-table-header
          @changeNot="changeNot($event)"
          @openModal="openModal($event)"
          @locateModal="locateModal($event, banner)"
          :newNotifications="newNotifications"
          :lang="lang"
          :banner="banner"
        />
        <img
          id="loading_table"
          :class="{
            'carga-not': banner == false,
            'carga-not-banner': banner == true,
          }"
          v-if="initialLoad"
          :src="require('@/assets/img/general/carga.gif')"
        />
        <div v-else-if="notifications.length != 0">
          <div id="notifications_table">
            <notifications-table-new
              v-show="newNotifications.length != 0"
              @openModal="openModal($event)"
              @locateModal="locateModal($event, banner)"
              :notifications="newNotifications"
              :banner="banner"
              :lang="lang"
            />
            <notifications-table-previous
              v-show="changeNotification == 0 && prevNotifications.length != 0"
              @openModal="openModal($event)"
              @locateModal="locateModal($event, banner)"
              :notifications="prevNotifications"
              :lang="lang"
              :banner="banner"
            />
            <div v-if="loading" class="align-center">
              <img
                class="view-more-load"
                :src="require('@/assets/img/general/carga.gif')"
              />
            </div>
            <div v-if="loading && loadNotifications == []" class="align-center">
              <img
                class="view-more-load"
                :src="require('@/assets/img/general/carga.gif')"
              />
            </div>
            <div
              @click="showMoreNotifications"
              v-if="countNotifications > 0 && loading == false && !banner"
              class="view-more-div"
            >
              <span
                v-show="changeNotification == 0"
                id="view-more"
                class="view-more"
                >{{ langFilter("view_more") }}</span
              >
            </div>
          </div>
        </div>
        <div
          v-else
          :class="{
            'no-notifications': !banner,
            'no-notifications-banner': banner,
          }"
        >
          {{ langFilter("no_notifications") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import notificationsMixin from "../../mixins/notificationsMixin.js";
import axios from "axios";
export default {
  props: [
    "banner",
    "deleteNotifications",
    "markAsReads",
    "markAllAsReads",
    "lang",
    "space_id",
  ],
  data() {
    return {
      newNotifications: [],
      notifications: [],
      prevNotifications: [],
      userDoAction: [],
      objectName: [],
      loadNewNotifications: [],
      loadNotifications: [],
      loadPrevNotifications: [],
      loadUserDoAction: [],
      loadObjectName: [],
      changeNotification: 0,
      openModalNotification: 0,
      countNotifications: 0,
      displayed: 20,
      loading: false,
      loaded: true,
      showLoad: false,
      initialLoad: true,
      traducciones: [
        {
          name: "notifications",
          es: "Notificaciones",
          en: "Notifications",
        },
        {
          name: "no_notifications",
          es: "No tienes notificaciones",
          en: "You don't have notifications",
        },
        {
          name: "view_more",
          es: "Ver más",
          en: "See more",
        },
      ],
    };
  },
  created() {
    this.getNotifications(0, 20);
    axios
      .post(`${process.env.VUE_APP_API_URL}/notifications/count`)
      .then((response) => {
        if (response.data == 0) {
          this.initialLoad = false;
        }
        this.countNotifications = response.data - 20;
      });
  },
  methods: {
    completeLoad() {
      this.notifications = [...this.notifications, ...this.loadNotifications];
      this.userDoAction = [...this.userDoAction, ...this.loadUserDoAction];
      this.objectName = [...this.objectName, ...this.loadObjectName];
      this.prevNotifications = [
        ...this.prevNotifications,
        ...this.loadPrevNotifications,
      ];
      this.newNotifications = [
        ...this.newNotifications,
        ...this.loadNewNotifications,
      ];
      this.loaded = false;
      // this.loading = false;
      this.showLoad = false;
      this.loadNewNotifications = [];
      this.loadPrevNotifications = [];
      this.loadObjectName = [];
      this.loadUserDoAction = [];
      this.loadNotifications = [];
    },
    changeNot(event) {
      this.changeNotification = event;
    },
    openModal(event) {
      this.$emit("openModal", event);
    },
    locateModal(event) {
      this.$emit("locateModal", event);
    },
    showMoreNotifications() {
      this.loading = true;
      this.showLoad = true;
      this.loadMoreNotifications();
    },
    loadMoreNotifications() {
      const moment = require("moment");
      moment.locale(this.lang);
      axios
        .post(`${process.env.VUE_APP_API_URL}/notifications/getNotifications`, {
          skip: this.displayed,
          take: 20,
          lang: this.lang,
          space_id: 22,
        })
        .then((response) => {
          this.loadNotifications = response.data;
          if (response.data.length != 0) {
            axios
              .post(`${process.env.VUE_APP_API_URL}/home/getUserDoAction`, {
                notifications: this.loadNotifications,
              })
              .then((response) => {
                this.loadUserDoAction = response.data;
                axios
                  .post(`${process.env.VUE_APP_API_URL}/home/getObjectName`, {
                    notifications: this.loadNotifications,
                  })
                  .then((response) => {
                    this.loadObjectName = response.data;
                    for (let i = 0; i <= this.loadNotifications.length; i++) {
                      if (this.loadUserDoAction[i] == undefined) {
                        if (this.lang == "es") {
                          this.loadUserDoAction[i] = "Alguien";
                        } else {
                          this.loadUserDoAction[i] = "Someone";
                        }
                      }
                      if (this.loadObjectName[i] == undefined) {
                        if (this.lang == "es") {
                          this.loadObjectName[i] = "el objeto";
                        } else {
                          this.loadObjectName[i] = "the object";
                        }
                      }
                      if (this.loadNotifications[i] != undefined) {
                        let finalMsg = this.loadNotifications[
                          i
                        ].message.replace(
                          "$user_do_action",
                          "<span class='bold-not-home'>" +
                            this.loadUserDoAction[i] +
                            "</span>"
                        );
                        finalMsg = finalMsg.replace(
                          "$object_name",
                          "<span class='bold-not-home'>" +
                            this.loadObjectName[i] +
                            "</span>"
                        );
                        this.loadNotifications[i].message = finalMsg;
                        this.loadNotifications[i].notification_date = moment(
                          this.loadNotifications[i].notification_date,
                          "YYYYMMDD"
                        ).fromNow();
                        this.loadNotifications[i].notification_date =
                          this.loadNotifications[i].notification_date
                            .charAt(0)
                            .toUpperCase() +
                          this.loadNotifications[i].notification_date.slice(1);
                      }
                    }
                    this.loadPrevNotifications = this.loadNotifications.filter(
                      function (el) {
                        return el.new == 0;
                      }
                    );
                    this.loadNewNotifications = this.loadNotifications.filter(
                      function (el) {
                        return el.new == 1;
                      }
                    );
                    this.loaded = true;
                  });
              });
          }
          this.countNotifications -= 20;
          if (this.countNotifications < 0) {
            this.displayed += 20 + this.countNotifications;
          } else {
            this.displayed += 20;
          }
          this.completeLoad();
          this.loading = false;
        });
    },
  },

  watch: {
    deleteNotifications: function (val) {
      const index = this.notifications.findIndex((e) => e.id === val);
      this.notifications.splice(index, 1);
      this.reorderNotifications();
      if (this.newNotifications.length == 0) {
        this.changeNotification = 0;
      }
    },
    markAsReads: function (val) {
      const index = this.notifications.findIndex((e) => e.id === val);
      if (this.notifications[index] != undefined) {
        if (this.notifications[index].new == 0) {
          this.notifications[index].new = 1;
        } else {
          this.notifications[index].new = 0;
        }
        this.reorderNotifications();
        this.$parent.markAsReads = 0;
        if (this.newNotifications.length == 0) {
          this.changeNotification = 0;
        }
      }
    },
    markAllAsReads: function () {
      this.notifications.map(function (x) {
        x.new = 0;
      });
      this.prevNotifications = this.notifications;
      this.newNotifications = [];
      this.$parent.markAllAsReads = 0;
      this.changeNotification = 0;
    },
  },

  mixins: [translationMixin, notificationsMixin],
};
</script>
