<style>
@import "../../src/assets/css/home/home.css";
</style>

<script setup>
import MyProjects from "../components/projects/MyProjectsComponent.vue";
</script>

<template>
  <MyProjects :role="role" :space="spaces[0]" :lang="lang" />
</template>

<script>
export default {
  props: ["role", "spaces", "lang"],
};
</script>
