<template>
  <div>
    <div
      id="solution-map-component"
      :class="[
        {
          mapOpened: $parent.mapOpened,
        },
        'solution-map-div',
      ]"
      v-if="!cdti"
    >
      <img
        v-if="$parent.mapOpened"
        @click="$parent.mapOpened = !$parent.mapOpened"
        class="solution-close-map"
        :src="require('@/assets/img/solution/close-map.svg')"
        alt="close-map"
      />
      <img
        v-else
        @click="$parent.mapOpened = !$parent.mapOpened"
        class="solution-close-map"
        :src="require('@/assets/img/solution/open-map.svg')"
        alt="close-map"
      />
      <progressbar-solution
        :class="[
          { 'percentage-solution-map': $parent.mapOpened },
          { 'percentage-solution-map-closed': !$parent.mapOpened },
        ]"
        :mapOpened="$parent.mapOpened"
        :map="true"
        :percentage="percentage"
      />

      <br />

      <div :class="{ 'solutions-map-div-opened': !$parent.mapOpened }">
        <div
          @click="sendCorrection()"
          @mouseover="showHideHoverCorrect(true)"
          @mouseleave="showHideHoverCorrect(false)"
          :class="[
            { 'solution-btn-map-closed': !$parent.mapOpened },
            { 'solution-btn-map-opened': $parent.mapOpened },
            { 'solution-btn-disabled': !solutionUpdated },
            'solution-btn-map',
          ]"
        >
          <img
            :src="require('@/assets/img/solution/send_correct.svg')"
            alt="Send Correct"
          />
          <span v-show="$parent.mapOpened" class="send-correct-txt">
            <span v-if="role == 1">{{ langFilter("sendCorrect") }}</span>
            <span v-else>{{ langFilter("sendCorrect2") }}</span>
          </span>
        </div>
        <div
          @click="$parent.mapOpened = !$parent.mapOpened"
          @mouseover="showHideHoverIndex(true)"
          @mouseleave="showHideHoverIndex(false)"
          :class="[
            { 'solution-btn-map-closed': !$parent.mapOpened },
            { 'solution-btn-map-opened': $parent.mapOpened },
            { 'solution-btn-map-index-opened': $parent.mapOpened },
            { 'solution-btn-map-index': $parent.mapOpened },
            'solution-btn-map',
          ]"
        >
          <img
            :src="require('@/assets/img/solution/index_logo.svg')"
            alt="Send Correct"
          />
          <span v-show="$parent.mapOpened" class="send-correct-txt">{{
            langFilter("index")
          }}</span>
        </div>
        <div
          @click="openModalResources()"
          @mouseover="showHideHoverResource(true)"
          @mouseleave="showHideHoverResource(false)"
          :class="[
            { 'solution-btn-map-opened': $parent.mapOpened },
            { 'solution-btn-map-resource-opened': $parent.mapOpened },
            'solution-btn-map',
            'solution-btn-map-resource',
          ]"
        >
          <img
            :src="require('@/assets/img/solution/resources_icon.svg')"
            alt="Send Correct"
          />
        </div>
        <div
          v-if="hoverResource"
          :class="[
            { 'solution-resource-hover': !$parent.mapOpened },
            { 'solution-resource-modal': $parent.mapOpened },
            'solution-map-hover',
          ]"
        >
          <p>{{ langFilter("resources") }}</p>
        </div>
        <div
          v-if="hoverIndex && !$parent.mapOpened"
          class="solution-map-hover solution-index-hover"
        >
          <p>{{ langFilter("index") }}</p>
        </div>
        <div
          v-if="hoverCorrect && !$parent.mapOpened"
          class="solution-map-hover solution-sendcorrect-hover"
        >
          <p v-if="role == 1">{{ langFilter("sendCorrect") }}</p>
          <p v-else>{{ langFilter("sendCorrect2") }}</p>
        </div>
      </div>
      <!-- map content -->
      <div
        v-if="reloadedMap"
        v-show="$parent.mapOpened"
        :key="changedProject"
        class="solution-map-content"
      >
        <table>
          <tr>
            <td class="solution-circle-map-td">
              <svg class="solution-grand-circle" height="20" width="25">
                <circle fill="#13C99E" cx="6" cy="6" r="6" />
              </svg>
              <svg
                width="2"
                height="14"
                stroke="#B4BAC5"
                class="map-line"
                style="position: absolute; left: 8.5px; bottom: -1"
              >
                <line stroke-width="2" y1="14"></line>
              </svg>
            </td>
            <td class="solution-map-padding">
              <p class="solution-start-finish-map solution-start">
                {{ langFilter("start") }}
              </p>
            </td>
          </tr>
        </table>
        <table
          v-for="(station, stationIndex) in allStations"
          :key="stationIndex"
        >
          <tr>
            <td class="solution-circle-map-td">
              <svg class="solution-grand-circle" height="20" width="25">
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  :fill="stationsColors[station.id] || '#B4BAC5'"
                />
              </svg>
              <svg
                width="2"
                height="14"
                stroke="#B4BAC5"
                class="map-line"
                style="position: absolute; left: 8.5px; top: -8px"
              >
                <line stroke-width="2" y1="14"></line>
              </svg>

              <svg
                width="2"
                height="14"
                stroke="#B4BAC5"
                class="map-line map-line-posc"
              >
                <line stroke-width="2" y1="14"></line>
              </svg>
            </td>
            <td class="solution-map-padding">
              <p
                :class="[
                  {
                    'solution-href-complete':
                      stationsColors[station.id] === '#13C99E',
                  },
                  'solution-map-station',
                ]"
              >
                {{ stationIndex + 1 }}. {{ station.name }}
              </p>
            </td>
          </tr>
          <tr
            v-for="(solution, solutionIndex) in $parent.getSolutionsbyStation(
              station.id
            )"
            :key="solutionIndex"
          >
            <td class="solution-circle-map-td">
              <div
                :style="
                  'background: ' + solutionsStatus[solution.id] || '#B4BAC5'
                "
                :id="'solution-border' + solution.id"
                :class="[
                  {
                    'solution-map-notification': checkNotification(solution.id),
                  },
                  'solution-little-circle',
                ]"
              />
              <svg
                width="2"
                height="14"
                stroke="#B4BAC5"
                class="map-line map-line-posc"
              >
                ,
                <line stroke-width="2" y1="14"></line>
              </svg>
            </td>
            <td class="solution-map-padding">
              <a
                :href="`/parts/${project_id}/${station.id}/${solution.id}`"
                onclick="event.preventDefault()"
              >
                <p
                  @click="goToSolution(station.id, solution.id, solution.name)"
                  class="solution-map-solution"
                >
                  {{ solution.name }}
                </p>
              </a>
            </td>
            <div
              v-if="solution.id === solution_id"
              class="solution-map-selected"
            />
          </tr>
        </table>
        <table>
          <tr>
            <td class="solution-circle-map-td">
              <svg class="solution-grand-circle" height="20" width="25">
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  :fill="percentage == 100 ? '#13C99E' : '#B4BAC5'"
                />
              </svg>
              <svg
                width="2"
                height="14"
                :stroke="percentage == 100 ? '#13C99E' : '#B4BAC5'"
                class="map-line"
                style="position: absolute; left: 8.5px; top: -10px"
              >
                <line stroke-width="2" y1="14"></line>
              </svg>
            </td>
            <td
              :class="[
                {
                  'solution-href-complete': allCompleted,
                },
                'solution-start-finish-map solution-finish',
              ]"
            >
              {{ langFilter("finished") }}
            </td>
          </tr>
        </table>
      </div>
      <img
        v-else
        class="solutions-loading-gif"
        :src="require('@/assets/img/general/loading.gif')"
      />
    </div>
    <!-- CDTI -->
    <div
      id="solution-map-component"
      :class="[
        {
          mapOpened: $parent.mapOpened,
        },
        'solution-map-div',
      ]"
      v-else
    >
      <div @click="sendInvertibility()" class="solution-btn-map-cdti">
        <img
          :src="require('@/assets/img/solution/send_correct.svg')"
          alt="Send Correct"
        />
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";

export default {
  props: [
    "project_id",
    "solution_id",
    "allStations",
    "allSolutions",
    "role",
    "lang",
    "percentage",
    "changedProject",
    "solution_updated",
    "cdti",
  ],
  data() {
    return {
      status: [],
      stationsColors: [],
      allCompleted: false,
      notificationMap: [],
      reloadedMap: true,
      hoverResource: false,
      hoverIndex: false,
      hoverCorrect: false,
      solutionUpdated: false,
      allAnswers: [],
      color: "#B4BAC5",
      solutionsStatus: [],
      allSolutionsMap: [],
      cont: 0,
      cdtiData: [],
      traducciones: [
        {
          name: "start",
          es: "Inicio",
          en: "Start",
        },
        {
          name: "finished",
          es: "Finalizado",
          en: "Finished",
        },
        {
          name: "sendCorrect2",
          es: "Solicitar revisión",
          en: "Request review",
        },
        {
          name: "sendCorrect",
          es: "Enviar revisión",
          en: "Send review",
        },
        {
          name: "index",
          es: "Índice",
          en: "Index",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "allQuestions",
          es: "Por favor responde al menos 8 preguntas.",
          en: "Please answer at least 8 questions.",
        },
      ],
    };
  },
  created() {
    if (this.cdti) {
      this.$parent.mapOpened = false;
    } else {
      this.$parent.mapOpened = true;
      this.solutionUpdated = this.solution_updated;
      this.getNotificationMap();
      this.getAnswersByProject();
      this.checkIfSolutionCompleted();
    }
  },

  methods: {
    openModalResources() {
      this.$emit("openModalResources");
    },
    sendInvertibility() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/cdti/getInsertedInvertibility/${this.project_id}/${this.solution_id}`
        )
        .then((response) => {
          const data = response.data;
          this.cdtiData = data;
          if (data.length < 8) {
            alert(this.langFilter("allQuestions"));
          } else {
            let result = data.find((item) => item.question_id === 32309);
            const data0 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32310);
            const data1 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32311);
            const data2 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32313);
            const data3 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32316);
            const data4 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32319);
            const data5 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32322);
            const data6 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32324);
            const data7 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32326);
            const data8 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32329);
            const data9 = result ? result.answer : null;

            result = data.find((item) => item.question_id === 32334);
            const data10 = result ? result.answer : null;

            axios
              .post(
                "https://acctest123.acceleralia.com:8443/answer/run-inference",
                {
                  q1: data0,
                  q2: data1,
                  q3: data2,
                  q4: data3,
                  q5: data4,
                  q6: data5,
                  q7: data6,
                  q8: data7,
                  q9: data8,
                  q10: data9,
                  q0: data10,
                }
              )
              .then((response) => {
                this.$emit("loadingCdti", true);
                const data = response.data;

                let step2 = [];
                data.explanation.forEach(function (element) {
                  if (
                    element.invertibility_index > data.invertibility_index &&
                    element.question_id !== "Q1" &&
                    element.question_id !== "Q0"
                  )
                    step2.push(element);
                });

                const result = step2.reduce((acc, curr) => {
                  if (
                    !acc[curr.question_id] ||
                    acc[curr.question_id].invertibility_index <
                      curr.invertibility_index
                  ) {
                    acc[curr.question_id] = curr;
                  }
                  return acc;
                }, {});

                //discard all the results with the object new_value = null or 0
                Object.keys(result).forEach(function (key) {
                  if (
                    result[key].new_value === null ||
                    result[key].new_value === 0
                  ) {
                    delete result[key];
                  }
                });

                const shuffled = Object.values(result).sort(
                  () => Math.random() - 0.5
                );
                const random3 = shuffled
                  .slice(0, 3)
                  .sort((a, b) => a.question_id.localeCompare(b.question_id));
                console.log("result", random3);
                this.$emit("getCdtiData", [data, random3, this.cdtiData]);
                this.$emit("closeCdtiModal", true);
                this.$emit("loadingCdti", false);
              });
          }
        });
    },
    getAnswersByProject() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/getAnswersByProject/${this.project_id}`
        )
        .then((response) => {
          this.allAnswers = response.data;
        });
    },
    solutionCompleted(solution_id, completed) {
      completed
        ? (this.solutionsStatus[solution_id] = "#13C99E")
        : (this.solutionsStatus[solution_id] = "#B4BAC5");
      this.checkIfStationCompleted();
    },
    getNotificationMap() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/solution/getNotificationMap`, {
          project_id: this.project_id,
          role: this.role,
        })
        .then((response) => {
          this.notificationMap = response.data;
        });
    },
    goToSolution(station_id, solution_id, solution_name) {
      this.$emit("goToSolution", [station_id, solution_id, solution_name]);
    },
    showHideHoverResource(show) {
      show ? (this.hoverResource = true) : (this.hoverResource = false);
    },
    showHideHoverIndex(show) {
      show ? (this.hoverIndex = true) : (this.hoverIndex = false);
    },
    showHideHoverCorrect(show) {
      show ? (this.hoverCorrect = true) : (this.hoverCorrect = false);
    },
    checkNotification(solution_id) {
      try {
        const notification = this.notificationMap.filter(
          (notification) => notification.solution_id === solution_id
        );
        if (notification[0].interaction || notification[0].correct) {
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    sendCorrection() {
      if (this.solutionUpdated) {
        this.$emit("sendCorrection");
      }
    },
    checkIfSolutionCompleted() {
      //wait 0.5 seconds to check if all solutions are completed
      setTimeout(() => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/solution/getSolutionsByProject/${this.project_id}`
          )
          .then((response2) => {
            this.allSolutionsMap = response2.data;
            let allSol = [];
            this.allSolutionsMap.forEach((solution) => {
              // if solution.id is in allSol
              if (!allSol.includes(solution.id)) {
                allSol.push(solution.id);
              }
            });
            for (let solution in allSol) {
              const solution_id = allSol[solution];
              //filter all answers by solution_id

              let answers = this.allAnswers.filter(
                (answer) => answer.solution_id === solution_id
              );
              //delete all answers with null answer value
              answers = answers.filter(
                (answer) => answer.answer !== null && answer.answer !== ""
              );
              //count all answers by solution_id
              const totalAnswers = answers.length;
              axios
                .get(
                  `${process.env.VUE_APP_API_URL}/solution/getQuestionsBySolution/${solution_id}`
                )
                .then((response) => {
                  if (
                    (totalAnswers === response.data ||
                      totalAnswers > response.data) &&
                    response.data !== 0
                  ) {
                    this.solutionsStatus[solution_id] = "#13C99E";
                  } else {
                    this.solutionsStatus[solution_id] = "#B4BAC5";
                  }
                  this.cont++;
                });
            }

            //execute every 250ms until this.allSolutionsMap.length === cont
            const interval = setInterval(() => {
              if (this.allSolutionsMap.length === this.cont) {
                clearInterval(interval);
                this.cont = 0;
                this.checkIfStationCompleted();
              }
            }, 250);
          });
      }, 1000);
    },
    checkIfStationCompleted() {
      //group all solutions by station_id
      let groupedSolutions = this.allSolutionsMap.reduce(
        (r, a) => {
          r[a.station_id] = [...(r[a.station_id] || []), a];
          return r;
        },
        { 0: [] }
      );
      //delete all empty solutions
      delete groupedSolutions[0];
      //iterate over all stations
      for (let station in groupedSolutions) {
        const station_id = groupedSolutions[station][0].station_id;
        //iterate over all solutions by station_id
        for (let solution in groupedSolutions[station]) {
          const solution_id = groupedSolutions[station][solution].id;
          //check if solution is completed
          if (this.solutionsStatus[solution_id] === "#B4BAC5") {
            this.stationsColors[station_id] = "#B4BAC5";
            break;
          } else {
            this.stationsColors[station_id] = "#13C99E";
          }
        }
      }
    },
  },
  watch: {
    changedProject: function () {
      this.reloadedMap = false;
    },
    solution_updated: function (newVal) {
      this.solutionUpdated = newVal;
    },
  },
  mixins: [translationMixin],
};
</script>
